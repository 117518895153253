
import axios from 'axios';
import { BackendUrl } from '../data/api';

import {FetchServices} from './shopActions';

export const RegisterShop = (shopData) => {
  return async (dispatch) => {
    dispatch({ type: 'REGISTER_START' });
    const res = await axios.post(`${BackendUrl}/accounts/shop/signup/`, shopData);
    console.log(res);
    if (res.status === 400) {
      dispatch({ type: 'FAILURE', payload: res.data });
    }
    if (res.status === 201) {
      dispatch({ type: 'REGISTER_SUCCESS', payload: res.data });
    }
  };
}

export const RegisterUser = (userData) => {
  return async (dispatch) => {
    dispatch({ type: 'REGISTER_START' });
    const res = await axios.post(`${BackendUrl}/accounts/customer/signup/`, userData);
    console.log(res);
    if (res.status === 400) {
      console.log('error 400');
      dispatch({ type: 'FAILURE', payload: res.data });
    }
    if (res.status === 201) {
      dispatch({ type: 'REGISTER_SUCCESS', payload: res.data });
    }
  };
}



export const LoginAction = (userData) => {
  return async (dispatch) => {
  await  dispatch({ type: 'LOGIN_START' });
  const res = await axios.post(`${BackendUrl}/accounts/login/`, userData);
  if(res?.data?.token){
    localStorage.setItem('token', res.data.token);
  }    
  if (res.status === 400) {
   await  dispatch({ type: 'FAILURE', payload: res.data });
  }else{
    await   dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
    await  dispatch(LoadUser(res.data.token));
    
  }
  };
}



export const LoadUser = (token) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${BackendUrl}/accounts/users/me/`, {
        headers: {
          'Authorization': `Token ${token}`
        },
      });
      dispatch({ type: 'USER_LOADED', payload: res.data });
      if (res.data.user_type === 'shop') {
        dispatch(FetchServices(token));
      }
    } catch (error) {
      console.log(error);
     
    }
  };
}


export const Logout = (token) => {
  console.log(token);
  return async (dispatch) => {
    try {
      await axios.get(`${BackendUrl}/accounts/logout/`,{
        headers: {
          'Authorization': `Token ${token}`
        },
      });
      localStorage.removeItem('token');
      dispatch({ type: 'LOGOUT' });
    } catch (error) {
      localStorage.removeItem('token');
      dispatch({ type: 'FAILURE', payload: error.response.data });
    }
  };
}





