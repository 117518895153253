import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useSelector , useDispatch} from 'react-redux';
import {LoginAction , LoadUser} from '../actions/authActions'
import CustomizedAlerts from '../Components/CustomizedAlerts';
import CircularProgress from '@mui/material/CircularProgress';
import {useNavigate} from 'react-router-dom'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavbarComponent from '../Components/Navbar/NavbarComponent';
import Footer from '../Components/Footer/Footer';


function Login () {
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const {isLoading , error , user , token} = useSelector(state => state.auth);
    useEffect(() => {
        if (user) {
            navigator('/dashboard');
        }
    }, [user, token, navigator]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        try{
            await dispatch(LoginAction(data));
            navigator('/dashboard');
        }
        catch(err){
            console.log(err);
            if(err.code=== "ERR_NETWORK"){
                await dispatch({ type: 'FAILURE', payload: {detail: "Network Error"} });
            }
            else{
                await dispatch({ type: 'FAILURE', payload: err?.response?.data });
            }
        }
        
    };
    
    return (
        <>
       
        <Box>
            <NavbarComponent />
            <CssBaseline />
            <Container component="main" maxWidth="xs"  sx={{ my: 15 }} >
                {
                    error && (
                        <CustomizedAlerts title="Your Email Id or Password is wrong" message={error.detail} severity="error" onClose={()=>dispatch({ type: 'CLEAR_ERROR' })} />
                    )
                }
                <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ m: 1,
                     bgcolor: '-moz-initial'
                     }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5"
                    color='-moz-initial'
                    >
                        Sign in
                    </Typography>
                    <Link to="/register" variant="body2">
                        {"Don't have an account? Sign Up"}
                    </Link>
                    {isLoading ? (
                        <Box sx={{ mt: 5, mb: 5 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}

                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link to='/forgotpassword' variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link to="/registershop" variant="body2">
                                        {"Business Owner? List with us"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Box>
            </Container>
            <CssBaseline />
            <Footer /> 
        </Box>
    </>
    );
}

export default Login;
