import { BackendUrl } from '../data/api';
import axios from 'axios';

export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE';

export const CREATE_SERVICE_REQUEST = 'CREATE_SERVICE_REQUEST';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_FAILURE = 'CREATE_SERVICE_FAILURE';

export const UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAILURE = 'UPDATE_SERVICE_FAILURE';

export const DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAILURE = 'DELETE_SERVICE_FAILURE';



const CreateServices = (formData , token) => {
    console.log('formData:', formData);
    console.log('token:', token);
    return async (dispatch) => {
        await dispatch({ type: CREATE_SERVICE_REQUEST });
        const response = await axios.post(`${BackendUrl}/shop/services/`, formData, {
            headers: {
              'Authorization': `Token ${token}`
            },
          });
        console.log('response:', response);
        if (response.status === 400) {
            dispatch({ type: CREATE_SERVICE_FAILURE, payload: response.data });
        }
        if (response.status === 201) {
            dispatch({ type: CREATE_SERVICE_SUCCESS, payload: response.data });
        }

    }   
}

const FetchServices = (token) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_SERVICES_REQUEST });
        const response = await axios.get(`${BackendUrl}/shop/services/`, {
            headers: {
              'Authorization': `Token ${token}`
            },
          });
        if (response.status === 400) {
            dispatch({ type: FETCH_SERVICES_FAILURE, payload: response.data });
        }
        if (response.status === 200) {
            dispatch({ type: FETCH_SERVICES_SUCCESS, payload: response.data });
        }
    }
}



export { CreateServices , FetchServices} 
