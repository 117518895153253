import React, { useEffect ,  useState } from 'react';
import CountUp from 'react-countup';

import {useDispatch ,  useSelector} from 'react-redux';
import {useNavigate } from 'react-router-dom';
export const Hero = () => {
  const dispatch = useDispatch();
  const [searchInputdata, setSearchInputdata] = useState("");
  const navigate = useNavigate();
  const handleFormSubmit = async(e) => {
    e.preventDefault();
    console.log("searchInputdata", searchInputdata);
    dispatch({
      type: "SET_SEARCH_INPUT",
      payload: searchInputdata
    });
    navigate("/search");
  }
  return (
    <div>
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row gy-4 d-flex justify-content-between">
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h2 data-aos="fade-up">Fueling your search for reliable automotive solutions</h2>
              <p data-aos="fade-up" data-aos-delay={100}>Using Throttle is simple! Look up your desired automotive service below and browse through professional and competitively-priced options we find near you</p>
              <form onSubmit={handleFormSubmit} className="form-search d-flex align-items-stretch mb-3" data-aos="fade-up" data-aos-delay={200}>
                <input type="text" className="form-control" placeholder="Search for automotive services or businesses" 
                value={searchInputdata}
                onChange={(e) => setSearchInputdata(e.target.value)}
                />
                <button type="submit" className="btn btn-primary"
                style={{ 
                  onHover: {backgroundColor: "black"},
                }
                }
                >Search</button>
              </form>
              <div className="row gy-4" data-aos="fade-up" data-aos-delay={400}>
                <div className="col-lg-3 col-6">
                  <div className="stats-item text-center h-100">
                    <CountUp start={0} end={100} delay={1}>
                    </CountUp>
                    <p>Automotive Services Available</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="stats-item text-center w-100 h-100">
                    <CountUp start={0} end={521} delay={1}>
                    </CountUp>
                    <p>Business Onboarded</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="stats-item text-center w-100 h-100">
                    <CountUp start={0} end={32} delay={1}>
                    </CountUp>
                    <p>Vehicles Booked</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6"></div>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out">
              <img src="assets/img/hero-img.svg" className="img-fluid mb-3 mb-lg-0" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
