import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
export default function ConfirmModel({
  open,
  handleClose,
  handleConfirm,
  title,
  content,
  loading,
  deleteSuccess,
  deleteError,
}) {
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        {loading && 
        <Container style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
        }
        {deleteError && 
          <p style={{ color: 'red' }}>
            Error deleting image. Please try again later.
          </p>
        }
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} autoFocus disabled={loading}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
