import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  Divider,
  CircularProgress,
  LinearProgress, 
  CardMedia,
  Button,
  TextField,
  Autocomplete,

} from "@mui/material";

import { Link } from "react-router-dom";
import {
  LocationOn as LocationOnIcon,
  Phone as PhoneIcon,
} from "@mui/icons-material";

import { ImageUrl } from "../data/api";

import { useSelector, useDispatch } from "react-redux";
import { searchInitialQuery } from "../actions/searchActions";

import Footer from "../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";

import { servicesList, categorieList } from '../data/serviceData';
import NavbarComponent from "../Components/Navbar/NavbarComponent";


const SearchPage = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [sortByPrice, setSortByPrice] = useState(false);
  const {
    searchInput,
    location,
    searchResults,
    loading,
    error,
    inputCategory,
    inputService,
  } = useSelector((state) => state.search);


  const [position, setPosition] = useState({ latitude: null, longitude: null })

  const handleSearchInput = () => {
    console.log("Search Input:", searchInput );
    try {
      dispatch(searchInitialQuery(searchInput));
    } catch (error) {
      console.log(error);
    }
  };


  const [filteredServices, setFilteredServices] = useState();
  const [filteredCategories, setFilteredCategories] = useState();

  

  const handleServisestore = async (value) => {
    setFilteredServices(value);
  };

  const handleCategoryStore = async (value) => {
    setFilteredCategories(value);
  };
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    
    if (!loading) {
      dispatch(searchInitialQuery(searchInput));
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })
      })
    } 
  }, [dispatch]);

  const handelAddService = (serviceId) => {
    localStorage.setItem("serviceId", serviceId);
    if (user?.user_type === "customer") {
      console.log(serviceId);
      navigate("/dashboard");
    } else {
      console.log("You need to login as a customer to add services");
      navigate("/login");
    }
  };


  const handleSearchupdate = (e) => {
    dispatch({ type: "SET_SEARCH_INPUT", payload: e.target.value });
  };


   // Define state for current page
   const [currentPage, setCurrentPage] = useState(1);

   // Calculate total pages based on number of results
   const totalPages = Math.ceil(searchResults.length / 3);
 
   // Function to handle next page
   const nextPage = () => {
     if (currentPage < totalPages) {
       setCurrentPage(currentPage + 1);
     }
   };
 
   // Function to handle previous page
   const prevPage = () => {
     if (currentPage > 1) {
       setCurrentPage(currentPage - 1);
     }
   };


   const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180); // Convert degrees to radians
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
  };


  const renderResults = () => {
    const startIndex = (currentPage - 1) * 3;
    const endIndex = currentPage * 3;
    const sortedResults = searchResults.sort((a, b) => {
      const distanceA = calculateDistance(
        position.latitude,
        position.longitude,
        a.latitude,
        a.longitude
      );

      const distanceB = calculateDistance(
        position.latitude,
        position.longitude,
        b.latitude,
        b.longitude
      );
      return distanceA - distanceB;
    });

    const currentResults = sortedResults.slice(startIndex, endIndex);

    

    if (error) {
      return <Typography variant="body1">Try Search Again</Typography>;
    } else {
      return (
        <Grid container spacing={2} direction="column">
          {currentResults.map((result) => (
            <Grid item key={result.id}>
              <Container sx={{ my: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <CardMedia
                      component="img"
                      image={`${ImageUrl}${result?.images[0]?.image}`}
                      alt="Selected Shop Image"
                      sx={{ objectFit: "cover", height: 120, width: 120 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Link
                        to={`/shop/${result.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="h6" gutterBottom>
                          {result.shop_name}
                        </Typography>
                      </Link>
                      <Typography variant="body1" gutterBottom>
                        {result.biography}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <LocationOnIcon />
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">
                            {result.street_address}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <PhoneIcon />
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">
                            {result.phone}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography variant="body1">
                        {result.street_address}, {result.city},{" "}
                        {result.zip_code}
                      </Typography>
                      {position.latitude && position.longitude && (
                        <Typography variant="body1">
                          Distance:{" "}
                          {calculateDistance(
                            position.latitude,
                            position.longitude,
                            result.latitude,
                            result.longitude
                          ).toFixed(2)}{" "}
                          km
                        </Typography>
                      )}
                      <Grid container spacing={1}>
                        {result.services
                          .filter(
                            (service) =>
                            (!filteredServices || service.service_name.includes(filteredServices)) && 
      (!filteredCategories || service.category.includes(filteredCategories)) 
                            
                          )
                          .slice((page - 1) * 6, page * 6)
                          .sort((a, b) =>
                            sortByPrice
                              ? a.service_price - b.service_price
                              : b.service_price - a.service_price
                          )
                          .map((service) => (
                            <Grid item key={service.id}>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handelAddService(service.id)}
                              >
                                {service.service_name} -${service.service_price}
                              </Button>
                            </Grid>
                          ))}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          ))}
        </Grid>
      );
    }
  };

  


  return (
    <Box sx={{ flexGrow: 1 }}>
      <NavbarComponent />
      <Container sx={{ my: 15 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
          <Container maxWidth="sm">
            <Paper elevation={3} style={{ backgroundColor: 'white', borderRadius: '10px'}}>
                <Grid container spacing={2} direction="column" sx={{p:1}}>
                    <Grid item>
                        <Typography variant="h6" sx={{ p: 2 }}>Search for an automotive service or business</Typography>
                        <Divider />
                        <TextField  
                            variant="outlined"
                            placeholder="Search Shop name, service or category"
                            value={searchInput}
                            onChange={handleSearchupdate}
                            fullWidth  
                        />
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" sx={{ p: 2 }}>Services</Typography>
                        <Divider />
                        <Autocomplete
                            options={servicesList}
                            onChange={(event, value ) => handleServisestore(value)}
                            renderInput={(params) => <TextField {...params} placeholder="Search Services" />}
                        />
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" sx={{ p: 2 }}>Categories</Typography>
                        <Divider />
                        <Autocomplete
                            options={categorieList}
                            onChange={(event, value) => handleCategoryStore(value)}
                            renderInput={(params) => <TextField {...params} placeholder="Search Categories" />}
                        />
                        <Divider />
                    </Grid>
                    <Grid item style={{display: 'flex', justifyContent: 'center', margin:'5px'}}>
                        <Button 
                            style={{width: '100%'}}
                            variant="contained" 
                            color="primary" 
                            onClick={handleSearchInput}
                            disabled={loading} // Disable the button when loading
                        >
                            {loading ? 'Searching...' : 'Search'}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper>
              {
                searchResults.length === 0
                 ? (
                  <Typography variant="h5" sx={{ p: 2 }}>
                    Search for a service or business
                  </Typography>
                ):(
              <Typography variant="h5" sx={{ p: 2 }}>
                Search Results for " {searchInput} "{" "}
                <Button
                  onClick={() => setSortByPrice(!sortByPrice)}
                  variant="outlined"
                  color="primary"
                  sx={{ ml: 2 }}
                >
                  Sort by Price {sortByPrice ? "(Low to High)" : "(High to Low)"}
                </Button>
              </Typography>
  )}
              <Divider />
              {loading && <LinearProgress />}
              {renderResults()}
            </Paper>
            {totalPages > 1 && (
                <Grid container justifyContent="center" sx={{ my: 2 }}>
                  <Button onClick={prevPage} disabled={currentPage === 1}>
                    Previous
                  </Button>
                  <Typography sx={{ mx: 2 }}>
                    Page {currentPage} of {totalPages}
                  </Typography>
                  <Button onClick={nextPage} disabled={currentPage === totalPages}>
                    Next
                  </Button>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default SearchPage;

