import React, { useState } from 'react';
import { Card, CardContent, Typography, Divider, Avatar, Grid, IconButton, Tooltip, TextField, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import { ImageUrl } from '../../data/api';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { BackendUrl } from '../../data/api';
import AvatarUpdate from './AvatarUpdate';
const ProfileField = ({ label, value, editable, onChange }) => {
    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={4}>
                <Typography variant="body1" color="textSecondary">{label}:</Typography>
            </Grid>
            <Grid item xs={8}>
                {editable ? (
                    <TextField
                        size="small"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                ) : (
                    <Typography variant="body1">
                        
                        {value === "undefined" ? "NA" : value}
                        </Typography>
                )}
            </Grid>
        </Grid>
    );
};

const ProfileSection = ({ title, icon, children }) => {
    return (
        <div style={{ marginBottom: '20px' }}>
            <Typography variant="h6" component="div" gutterBottom>
                {icon} {title}
            </Typography>
            {children}
        </div>
    );
};

const ProfileDetails = () => {
    const theme = useTheme();
    const user = useSelector((state) => state.auth.user);
    const {token} = useSelector((state) => state.auth);
    const [editable, setEditable] = useState(false);
    const [updatedUser, setUpdatedUser] = useState(user);
    const [profileUpdating , setProfileUpdating] = useState(false)
    const handleFieldChange = (field) => (value) => {
        setUpdatedUser((prevUser) => ({
            ...prevUser,
            [field]: value,
        }));
    };

    const handleEditToggle = () => {
        setEditable((prevEditable) => !prevEditable);
    };

    const handleAvatarUpdate = (avatarUrl) => {
        setUpdatedUser((prevUser) => ({
          ...prevUser,
          profile_picture_path: avatarUrl 
        }));
      };
    const handleSaveChanges = () => {
        console.log('Updated user details:', updatedUser);
        setProfileUpdating(true)
        try {
            axios
                .put(`${BackendUrl}/shop/customer/${user.id}/`, updatedUser,{
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'multipart/form-data'
                      }
                })
                .then((response) => {
                    console.log('User details updated:', response.data);
                    setEditable(false);
                })
                .catch((error) => {
                    console.error('Error updating user details:', error);
                });
        }
        catch (error) {
            console.error('Error updating user details:', error);
        }
        setTimeout(() => {
            setProfileUpdating(false)
            setEditable(false);
        }
        , 3000)
       
    };

    return (
       
        <Card variant="outlined" sx={{ margin: '20px', marginTop: '80px'  }}>
            <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center" gap={2} padding={2} borderRadius={8} boxShadow={1} >
      <Avatar alt={user.first_name} src={ImageUrl+
        updatedUser.profile_picture_path
      } sx={{ width: 120, height: 120 }} />
      {editable && <AvatarUpdate userId={user.id} token={token} onAvatarUpdate={handleAvatarUpdate} />}
      <Typography color='black' variant="h4" >{user.first_name} {user.last_name}</Typography>
      <Divider style={{ width: '100%' }} />
      <Box display="flex" justifyContent="space-between" width="100%">
        </Box>
    </Box>
                <Divider style={{ marginBottom: '20px' }} />
                <ProfileSection title="Contact Information" icon={<LocationOnIcon />}>
                    <ProfileField label="Email"  value={user.email} />
                    <ProfileField label="Phone" value={updatedUser.phone} editable={editable} onChange={handleFieldChange('phone')} />
                </ProfileSection>
                <ProfileSection title="Address" icon={<LocationOnIcon />}>
                    <ProfileField label="Street Address" value={updatedUser.street_address} editable={editable} onChange={handleFieldChange('street_address')} />
                    <ProfileField label="City" value={updatedUser.city} editable={editable} onChange={handleFieldChange('city')} />
                    <ProfileField label="Province" value={updatedUser.province} editable={editable} onChange={handleFieldChange('province')} />
                    <ProfileField label="Zip Code" value={updatedUser.zip_code} editable={editable} onChange={handleFieldChange('zip_code')} />
                </ProfileSection>
                <ProfileSection title="Car Information" icon={<DirectionsCarIcon />}>
                    <ProfileField label="Car Model" value={updatedUser.Car_model} editable={editable} onChange={handleFieldChange('Car_model')} />
                    <ProfileField label="Car Make" value={updatedUser.Car_make} editable={editable} onChange={handleFieldChange('Car_make')} />
                    <ProfileField label="Car Year" value={updatedUser.Car_year} editable={editable} onChange={handleFieldChange('Car_year')} />
                    <ProfileField label="Car Type" value={updatedUser.Car_type} editable={editable} onChange={handleFieldChange('Car_type')} />
                </ProfileSection>
                <Divider style={{ margin: '20px 0' }} />
                <Grid container justifyContent="flex-end">
                    {!editable ? (
                        <Tooltip title="Edit">
                            <IconButton onClick={handleEditToggle} color="inherit">
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveChanges}
                            >
                                {profileUpdating ? 'Updating.....' : 'Save Changes'}
                            </Button>

                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleEditToggle}
                                style={{ marginLeft: '10px' }}
                            >
                                Cancel
                            </Button>
                        </Box>

                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ProfileDetails;
