import React from "react";
import AboutCompnent from "../Components/AboutCompnent";
import NavbarComponent from "../Components/Navbar/NavbarComponent"
const About = () => {
  return (
    <main id="main">
      {/* ======= Breadcrumbs ======= */}
      <NavbarComponent />
      <AboutCompnent />
    </main>
  );
};

export default About;
