import React from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

const ServicesComponent = () => {
  const { user , token} = useSelector((state) => state.auth);

  return (
    <div>
      <section id="service" className="services">
        <div className="container">
          <div className="section-header" data-aos="fade-up">
            <span>Popular Services</span>
            <h2>Popular Services</h2>
          </div>
          <div className="row gy-4">
            <ServiceCard
              imgSrc="assets/image/Oilchange_2.jpg"
              title="Oil Change"
              link="/"
              delay={100}
              description={[
                "Regular maintenance procedure involving draining old engine oil and replacing it with fresh oil to ensure proper lubrication and engine performance.",
                "When to Happen: Typically every 3,000 to 5,000 miles, but can vary based on the vehicle and oil type. Check the owner's manual for specific recommendations."
              ]}
            />
            <ServiceCard
              imgSrc="assets/image/Transmission_2.jpg"
              title="Transmission Fluid Change"
              link="/"
              delay={200}
              description={[
                "Process of removing old transmission fluid and replacing it with new fluid to maintain smooth operation and extend the life of the transmission.",
                "When to Happen: Generally recommended every 30,000 to 60,000 miles, but consult the vehicle's manual for specific guidelines."
              ]}
            />
            <ServiceCard
              imgSrc="assets/image/Brakes_2.jpg"
              title="Brake and Rotor Pads Change"
              link="/"
              delay={300}
              description={[
                "Replacement of worn-out brake pads and, if necessary, resurfacing or replacing brake rotors to ensure effective braking.",
                "When to Happen: Typically needed every 25,000 to 70,000 miles, depending on driving habits and the type of brake pads. Regular inspections are advisable."
              ]}
            />
            <ServiceCard
              imgSrc="assets/image/Diagnostic_2.jpg"
              title="General Car Diagnostic"
              link="/"
              delay={400}
              description={[
                "Comprehensive evaluation of the vehicle's systems using diagnostic tools to identify and address any potential issues or irregularities.",
                "When to Happen: As needed or during regular maintenance appointments. If warning lights appear or the vehicle shows signs of problems, a diagnostic check is essential."
              ]}
            />
            <ServiceCard
              imgSrc="assets/img/airFilter.jpeg"
              title="Air Filter Replacement"
              link="/"
              delay={500}
              description={[
                "Replacement of the engine air filter to ensure optimal air quality for combustion and prevent contaminants from entering the engine.",
                "When to Happen: Typically every 15,000 to 30,000 miles, but it depends on driving conditions. Check the owner's manual for specific recommendations."

              ]}
            />
            <ServiceCard
              imgSrc="assets/image/Tirechange_2.jpg"
              title="Tire Change"
              link="/"
              delay={600}
              description={[
                "Replacing old or worn-out tires with new ones to maintain proper traction, handling, and safety on the road.",
                "When to Happen: Varies based on the type of tire and driving conditions. Regularly check the tire tread and consider replacement every 25,000 to 50,000 miles or as needed based on wear."
              ]}
            />
            {/* <ServiceCard
              imgSrc="assets/img/warehousing-service.jpg"
              title="Car Cleaning and Detailing"
              link="/"
              delay={600}
              description={[
                "Thorough cleaning and restoration of a vehicle's interior and exterior, including washing, waxing, vacuuming, and polishing, to enhance its aesthetic appeal and preserve its overall condition.",
                "When to Happen: Frequency can vary based on personal preferences and environmental factors. However, a detailed cleaning is recommended every 3 to 6 months to maintain the vehicle's appearance and protect the paint and interior surfaces. More frequent cleaning may be needed in areas with harsh weather conditions or if the vehicle is frequently exposed to dirt and debris."
              ]}
            />
            <ServiceCard
              imgSrc="assets/img/warehousing-service.jpg"
              title="Repair"
              link="/"
              delay={600}
              description={[
                "Process of restoring a vehicle to its pre-damaged condition following accidents, collisions, vandalism, or environmental factors. This encompasses the repair of various components of the vehicle, including body panels, paintwork, glass, and structural elements, with the aim of repairing or replacing damaged parts to ensure the vehicle's safety, functionality, and aesthetics.",
                "When to Happen: Timely and professional repair of damages is essential to preserve the vehicle's value, ensure its longevity, and maintain its roadworthiness."

              ]}
            /> */}
          </div>
        </div>
      </section>
      <section id="call-to-action" className="call-to-action">
        <div className="container" data-aos="zoom-out">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h3>Book Appointment Now</h3>
              <p>
              Schedule your next automotive service appointment, get in touch with a Throttle verified automotive service provider
                </p>

                {
                  user ? (
                    <Link className="cta-btn" to='/'>Book Now </Link>
                  ):(
                    <Link className="cta-btn" to='/login'>Login Now </Link> 
                  )
                }

              {/*  */}
              {/* */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const ServiceCard = ({ imgSrc, title, link, delay, description }) => {
  
  const listStyle = {
    margin: '10px'
  };

  return (
    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={delay}>
      <div className="card">
        <div className="card-img">
          <img src={imgSrc} alt={title} className="img-fluid" />
        </div>
        <h3><a href={link} className="stretched-link">{title}</a></h3>
       
        <ul style={listStyle}>
          {description.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ServicesComponent;
