import React from 'react'
import { Link } from 'react-router-dom'

const Featured = () => {
  return (
    <div>
        <main id="main">
    <section id="featured-services" className="featured-services">
      <div className="container">
      <div className="section-header">
            <span>How it Works!</span>
            <h2>How it Works!</h2>
          </div> 
        <div className="row gy-4">
          <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up">
            <div className="icon flex-shrink-0"><i className="fa-solid fa-magnifying-glass-location"></i></div>
           
            <div>
              <h4 className="title">Find Automotive Services</h4>
              <p className="description"> Search for your next automotive service above and we will recommend options near you </p>
              <Link to='/services' className="readmore stretched-link"><span>More Services</span><i className="bi bi-arrow-right" /></Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={100}>
            <div className="icon flex-shrink-0"><i className="fa-solid fa-code-compare" /></div>
            <div>
              <h4 className="title">Make an Informed Decision</h4>
              <p className="description">Compare price, location, and quality to select what fits your needs. Look through our wide range of services offered by verified providers</p>
              <Link to='/services' className="readmore stretched-link"><span>More Services</span><i className="bi bi-arrow-right" /></Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={200}>
            <div className="icon flex-shrink-0"><i className="fa-solid fa-calendar-check" /></div>
            <div>
              <h4 className="title">Schedule Appointments</h4>
              <p className="description">Get in touch right away and schedule your visit to address your automotive needs right away!</p>
              <Link to='/services' className="readmore stretched-link"><span>More Services</span><i className="bi bi-arrow-right" /></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    </main>
    </div>
  )
}

export default Featured