import * as React from "react";
import {
  createBrowserRouter,
} from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Services from "./Pages/Services";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import ListShop from "./Pages/ListShop";
import ForgotPassword from "./Pages/ForgotPassword";
import EmailVarification from './Pages/EmailVarification'
import About from "./Pages/About";
import ContactPage from "./Pages/ContactPage";
import TermsOfServices from "./Pages/TermsOfServices";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import NewPasswordSet from "./Pages/NewPasswordSet";
import ProtectedRoute from "./Components/ProtectedRoute";
import Dashboard from "./Pages/Dashboard";

import Appointment from './Components/Shops/Appointment';
import Profile from './Components/Shops/Profile';
import AutoServices from "./Components/Shops/AutoServices";
import ShopHome from "./Components/Shops/ShopHome";
import SearchPage from "./Pages/SearchPage";

import ShopShowcasePage from "./Pages/ShopShowcasePage";
import Pricing from "./Pages/Pricing";
import { Check } from "@mui/icons-material";

import CheckoutForm from "./Components/payment/CheckoutForm";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51P7HccBL2uRZElUz12YLdNsfqdjziUmIN3vnYmSCuUBGFQzZ3X1IgF5ufbZ5xkj1odilwdcmcIpzXfdqgpzJuIRY00qdy9OyrE');


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage/>,
  },
  {
    path: "/services",
    element: <Services/>
  },
  {
    path: "/about",
    element: <About/>
  },
  
  {
    path: "/contact",
    element: <ContactPage/>
  },{
    path:'/login',
    element:<Login/>
  },
  {
    path:'/register',
    element:<Register/>
  },
  {
    path:'/registershop',
    element:<ListShop/>
  },
  {
    path:'/search',
    element: <SearchPage/>
  },
  {
    path :'/verify/:code',
    element:<EmailVarification/>
  },
  {
    path:'/forgotpassword',
    element:<ForgotPassword/>
  },
  {
    path:'/resetpassword/:code', 
    element:<NewPasswordSet/>
  },
  {
    path :'/shop/:id',
    element:<ShopShowcasePage/>
  },
  {
    path:'/dashboard',
    element: <ProtectedRoute><Dashboard/> </ProtectedRoute>,
    children: [
      {
        path: "/dashboard",
        element: <ShopHome/>
      },
      {
        path: "/dashboard/appointment",
        element: <Appointment/>
      },
      {
        path: "/dashboard/Services",
        element: <AutoServices/>
      },
      {
        path: "/dashboard/profile",
        element: <ProtectedRoute><Profile/></ProtectedRoute>
      },
    ]
  },
  {
    path:'/pricing',
    element:<Pricing/>
  },
  {
    path:'/checkout',
    element:<ProtectedRoute>
      <Elements stripe={stripePromise}>
      <CheckoutForm/>
      </Elements>
      </ProtectedRoute>
    // element:<CheckoutForm/>
  },
  {
    path:'/terms',
    element:<TermsOfServices/>
  },
  {
    path:'/privacy',
    element: <PrivacyPolicy/>
  },
  {
    path :'*',
    element:<h1>
      404 Page not found
    </h1>
  }
]);

export default router;
