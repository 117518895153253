import React from 'react'

const Faqs = () => {
  return (
    <div>

      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <span>Frequently Asked Questions</span>
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row justify-content-center" data-aos="fade-up" data-aos-delay={200}>
            <div className="col-lg-10">
              <div className="accordion accordion-flush" id="faqlist">
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                      <i className="bi bi-question-circle question-icon" />
                      How Throttle works?

                    </button>
                  </h3>
                  <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                    <div className="accordion-body">
                      <ol>
                        <li>
                          Determine the specific car service needed.
                        </li>
                        <li>
                          Enter your vehicle information and address.
                        </li>
                        <li>
                          Browse and choose from mechanics that match your criteria.
                        </li>
                        <li>
                          Schedule an appointment based on your availability.
                        </li>
                        <li>
                          Receive confirmation from the chosen mechanic shop for your car drop-off.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>{/* # Faq item*/}
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                      <i className="bi bi-question-circle question-icon" />
                      What makes Throttle unique?
                    </button>
                  </h3>
                  <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                    <div className="accordion-body">
                      Throttle distinguishes itself in several ways, making it unique in the automotive services industry:
                      <ol>
                        <li>
                          Curated Network of Mechanic Shops:
                          <p
                            style={{ fontSize: 14, marginLeft: 10 }}
                          >
                            Throttle offers a curated network of mechanic shops, ensuring that users have access to trustworthy and reliable service providers. By carefully selecting and vetting mechanic shops, Throttle guarantees quality service and peace of mind for its users.
                          </p>
                        </li>
                        <li>
                          Streamlined Booking Experience:
                          <p
                            style={{ fontSize: 14, marginLeft: 10 }}
                          >
                            Throttle provides a seamless and user-friendly platform for searching and booking automotive services. With intuitive navigation and easy-to-use features, users can quickly find the services they need and schedule appointments with mechanic shops hassle-free.
                          </p>
                        </li>
                        <li>
                          Transparent Pricing:
                          <p
                            style={{ fontSize: 14, marginLeft: 10 }}
                          >
                            Throttle prioritizes transparency in pricing, allowing users to compare rates and select the best option for their needs and budget. By providing upfront pricing information and disclosing potential price changes, Throttle empowers users to make informed decisions about their automotive services.
                          </p>
                        </li>
                       
                      </ol>
                    </div>
                  </div>
                </div>{/* # Faq item*/}
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                      <i className="bi bi-question-circle question-icon" />
                      What are the key features?
                    </button>
                  </h3>
                  <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                    <div className="accordion-body">
                      <ol>
                        <li>
                        Search and Discovery: 
                          <p
                            style={{ fontSize: 14, marginLeft: 10 }}
                          >
                           Throttle offers a comprehensive search and discovery platform, allowing users to easily find mechanic shops based on location, services offered, ratings, and reviews. Users can explore a curated network of trusted mechanic shops to find the best fit for their automotive needs.

                          </p>
                        </li>
                        <li>
                        Transparent Pricing: 

                          <p
                            style={{ fontSize: 14, marginLeft: 10 }}
                          >
                            TThrottle promotes transparency in pricing by providing upfront cost estimates for automotive services. Users can view pricing details for different services and compare rates from various mechanic shops, empowering them to make informed decisions about their vehicle maintenance and repairs
                          </p>
                        </li>
                        <li>
                        Booking and Scheduling: 
                          <p
                            style={{ fontSize: 14, marginLeft: 10 }}
                          >
                          Throttle provides a streamlined booking and scheduling experience, enabling users to schedule appointments with mechanic shops directly through the platform. Users can select their preferred date and time for service, receive confirmation notifications, and manage their bookings effortlessly.

                          </p>
                        </li>
                        <li>
                        User Reviews and Ratings: 

                          <p
                            style={{ fontSize: 14, marginLeft: 10 }}
                          >
                            Throttle features user reviews and ratings for mechanic shops, allowing users to read feedback from other customers and evaluate the quality of service before booking an appointment. This helps users make confident choices and fosters trust within the Throttle community.

                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>{/* # Faq item*/}
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                      <i className="bi bi-question-circle question-icon" />
                      Why partner with Throttle?
                    </button>
                  </h3>
                  <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                    <div className="accordion-body">
                      <i className="bi bi-question-circle question-icon" />
                      Throttle boasts a robust customer base, offering you direct access to proactive car owners seeking services. Utilizing it as a marketing tool will amplify your shop's online visibility, fostering growth opportunities. By enabling both existing and potential customers to book services directly through Throttle, it will fuel expansion and drive business growth.
                    </div>
                  </div>
                </div>{/* # Faq item*/}
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-5">
                      <i className="bi bi-question-circle question-icon" />
                      How to contact us?
                    </button>
                  </h3>
                  <div id="faq-content-5" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                    <div className="accordion-body">
                    You can contact us directly at <span style={{ color: '#f82249' }}></span>
                  
                      <a href="mailto:info@throttleauto.ca">
                      info@throttleauto.ca
                          </a>
                    </div>
                  </div>
                </div>{/* # Faq item*/}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Faqs