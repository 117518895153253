import router from './Router'; 
import { RouterProvider } from 'react-router-dom';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { useEffect } from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { LoadUser } from './actions/authActions';



const theme = createTheme({
  palette: {
    primary: {
      main: '#0d42ff',
    },
    secondary: {
      main: '#0E1D34',
    }
  },
  typography: {
    fontFamily: [
      'Inter',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});


function App() {

  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);

  const loadApp = async () => {
    const token = localStorage.getItem('token');
    if(token){
      dispatch(LoadUser(token));
    }
  }
  useEffect(() => {
    loadApp();
  },[])
  return (
      <ThemeProvider theme={theme}>
   <RouterProvider router={router}>
    {router}
   </RouterProvider>
    </ThemeProvider>
  );
}

export default App;
