import React from "react";
import NavbarComponent from "../Components/Navbar/NavbarComponent";
import Footer from "../Components/Footer/Footer";
import Sidebar from '../Components/Sidebar'
import {
  Box,
  Container,
  Grid
}
from "@mui/material";
const PrivacyPolicy = () => {
  return (
    <>
    <NavbarComponent/>
    <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
    <Container
    sx={{my: 10,}}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3} 
        style={{
          borderRight: '2px solid #ccc',
          height: '100%',
         
        }}
        >
        <Sidebar />
        </Grid>
        <Grid item xs={12} md={9} lg={9}>
        <h1 className="text-4xl font-bold">Privacy Policy</h1>
      <div class="basis-1/2 lg:py-0 lg:px-8 md:px-5 px-0 md:py-0 py-8">
        <p className="font-serif text-lg">
          This Privacy Policy describes how Throttle Operations Inc.
          ("Throttle," "we," "us," or "our") collects, uses, and protects the
          personal information of users who access or use our website and
          related services for searching and booking mechanic shops
          ("Services").
        </p>
        <p className="font-bold">1. Information We Collect</p>
        <div>
          <ul className="text-lg ">
            <li>
              <strong> Personal Information: </strong> When you use our
              Services, we may collect personal information that you voluntarily
              provide, such as your name, email address, phone number, and
              billing information.
            </li>
            <li>
              <strong>  Usage Data: </strong> We may also collect non-personal
              information about your interactions with our website, including IP
              address, browser type, pages visited, and device information.
            </li>
          </ul>
        </div>

        <p className="font-bold">2. How We Use Your Information</p>
        <div>
            <p>
            We use the information we collect for the
        following purposes:
            </p>
          <ul className=" text-lg ">
            <li>
            To provide and maintain our Services. 
            </li>
            <li>
             To process
        bookings and payments for mechanic shop services. 
            </li>
            <li>
             To communicate with
        you regarding your bookings, inquiries, and account-related updates.
            </li>
            <li>
            
        To improve and personalize your experience with our Services. 
            </li>
            <li>
             To
        analyze usage trends and optimize the performance of our website.
            </li>
          </ul>
        </div>
        <p className="font-bold">
        3. Sharing of Information 
        </p>
        <p className="text-lg text-black">
       We may share your personal information with
        third-party service providers who assist us in operating our business
        and providing our Services, such as payment processors and customer
        support platforms. We may also disclose your information if required by
        law or to protect our rights or the rights of others.
        </p>

        <p className="font-bold">
        4. Data Security
        </p>
        <p className="text-lg text-black">
        We take appropriate security measures to protect your personal
        information from unauthorized access, alteration, disclosure, or
        destruction. However, please be aware that no method of transmission
        over the internet or electronic storage is 100% secure, and we cannot
        guarantee absolute security.
        </p>
        <p className="font-bold">
        5. Data Retention
        </p>
        <p className="text-lg text-black">
        We will retain your
        personal information for as long as necessary to fulfill the purposes
        outlined in this Privacy Policy, unless a longer retention period is
        required or permitted by law.
        </p>
        <p className="font-bold">
        6. Your Choices
        </p>
        <p className="text-lg text-black">
        You may choose not to
        provide certain personal information, but this may limit your ability to
        access certain features of our Services. You may also opt out of
        receiving promotional communications from us by following the
        instructions provided in such communications.
        </p>
        <p className="font-bold">
        7. Children's Privacy
        </p>
        <p className="text-lg text-black">
        Our Services are not intended for individuals under the age of 18. We do not
        knowingly collect personal information from children under 13. If you
        are a parent or guardian and believe that your child has provided us
        with personal information, please contact us so that we can take
        appropriate action.
        </p>
        <p className="font-bold">
        8. Updates to this Privacy Policy 
        </p>
        <p className="text-lg text-black">
        We may update this
        Privacy Policy from time to time by posting the revised version on our
        website. We encourage you to review this page periodically for any
        changes. Your continued use of our Services after the posting of changes
        constitutes your acceptance of such changes.
        </p>
        <p className="font-bold">
        9. Contact Us
        </p>
        <p className="text-lg text-black">
        If you have
        any questions or concerns about this Privacy Policy or our privacy
        practices,<strong> please contact us at {" "}<a href="mailto:info@throttleauto.ca">
      info@throttleauto.ca.
        </a>
          </strong> 
        </p>
      
      </div>
      </Grid>
      </Grid>
      </Container>
    </Box>

    <Footer/>
    </>
  );
};

export default PrivacyPolicy;