// import React from 'react'
// import { useSelector, useDispatch } from 'react-redux'
// import { Logout } from '../actions/authActions'
// import { 
//     Container,
//     Typography,
//     Button,
//     Box,
//     Grid // Import Grid for layout
// } from "@mui/material"

// import NavBar from '../Components/DashBoard/NavBar'
// import Template from '../Components/DashBoard/Template'
// import UserDetails from '../Components/DashBoard/UserDetails'
// import Footer from '../Components/Footer/Footer'
// const UserDashBord = () => {
//     const dispatch = useDispatch()
//     const { user } = useSelector((state) => state.auth)

//     return (
//         <Box>
//         <Container sx={{ my: 15 }}>
//             <NavBar />
//             <Grid container spacing={3}>
//                 <Grid item xs={12} md={7}> 
//                     <Box>
//                         <Typography variant="h4">Upcoming Appointments</Typography>
//                         <Typography variant="h4">Requested Appointments</Typography>
//                         <Typography variant="h4">Old Appointments</Typography>
//                     </Box>
//                 </Grid>
//                 <Grid item xs={12} md={5}> 
//                     <UserDetails user={user} />
//                 </Grid>
//             </Grid>
//         </Container>
//         <Footer/>
//         </Box>
//     )
// }

// export default UserDashBord
import React from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Logout } from '../actions/authActions';
import {
    Container,
    Typography,
    Button,
    Box,
} from "@mui/material";


import Footer from '../Components/Footer/Footer';

import { UserHome } from '../Components/DashBoard/UserHome';
import UserDetails from '../Components/DashBoard/UserDetails'
import NavbarComponent from '../Components/Navbar/NavbarComponent'


const UserDashBord= () => {

    return (
        <Box>
            <NavbarComponent/>
           
            <Box component="main" >
                <Routes>
                    <Route path="" element={<UserHome/>} />
                    {/* <Route path="appointment" element={<Appointments />} /> */}
                    {/* <Route path="services" element={<AutoServices />} /> */}
                    <Route path="profile" element={<UserDetails />} />
                </Routes>
            </Box>
          
            <Footer />
        </Box>
    );
};

export default UserDashBord;
