
import axios from "axios";
import { BackendUrl } from "./api";


const API_URL = "/payments/save-stripe-info/";
export const api = axios.create({
    baseURL: BackendUrl,
    headers: {
      "Content-type": "application/json"
    }
  });
  export default class ApiService{
    static saveStripeInfo(data={}){
      return api.post(`${API_URL}`, data)
    }
  }