import React from 'react';
import { Link } from 'react-router-dom';
import {useLocation , useParams} from 'react-router-dom';
import {Divider,
    List,
    ListItem,
    ListItemText,
    Box
} from '@mui/material';


const Sidebar = ({ name }) => {
    const page = useLocation().pathname;
    console.log(page);
  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper'  }}

    >
        <List component="nav" aria-label="main mailbox folders">
            <ListItem button component={Link} to="/terms" 
            style={{backgroundColor: page === '/terms' ? 'lightgrey' : 'white'}}
            >
            <ListItemText primary="Terms of Services"
            />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to="/privacy" 
            style={{backgroundColor: page === '/privacy' ? 'lightgrey' : 'white'}}
            >
            <ListItemText primary="Privacy Policy" />
            </ListItem>
        </List>
    </Box>
  );
};

export default Sidebar;
