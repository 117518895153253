import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  Grid,
  Card,
  CardMedia,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModel from "../Shops/ConfirmModel";
import { BackendUrl } from "../../data/api";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import { ImageUrl } from "../../data/api";

const ImageGallery = ({ profileInfo, handleImageDelete }) => {
  return (
    <Grid container spacing={2}>
      {profileInfo.images.map((image) => (
        <Grid item key={image.id} xs={6} md={4} lg={3}>
          <Card sx={{ position: "relative", height: "100%" }}>
            <CardMedia
              component="img"
              image={ImageUrl + image.image}
              alt="Shop Image"
              sx={{ height: 200, objectFit: "cover" }}
            />
            <IconButton
              sx={{
                position: "absolute",
                top: 4,
                right: 4,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                borderRadius: "50%",
              }}
              onClick={() => handleImageDelete(image.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const Profile = () => {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.auth);
  const [profileInfo, setProfileInfo] = useState(user);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [imageIdToDelete, setImageIdToDelete] = useState(null);
  const [images, setImages] = useState([]);
  const [imageUploading, setImageUploading] = useState(false);
  const [profileUpdating, setprofileUpdating] = useState(false);
  const [phoneError, setPhoneError] = useState("");

  const handleUploadImages = () => {
    setImageUploading(true);
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append(`image${i}`, images[i]);
    }
    console.log("formData:", [...formData]);
    axios
      .post(`${BackendUrl}/shop/shopImages/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("response:", response);
        if (response.status === 201) {
          setProfileInfo((prevProfileInfo) => ({
            ...prevProfileInfo,
            images: [...response.data],
          }));
          setImages([]);
          setImageUploading(false);
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setImageUploading(false);
      });
  };
  const handleProfileUpdate = () => {
    const isValidPhoneNumber = /^\d{10}$/.test(profileInfo.phone);
    if (!isValidPhoneNumber) {
      // alert("Mobile number should be 10 digits.");
      setPhoneError("Mobile number should be 10 digits.");
      return; // Prevent further execution if phone number is invalid
    }
    setprofileUpdating(true);
    console.log("profileInfo:", profileInfo);
   
    axios
      .put(`${BackendUrl}/shop/shops/${profileInfo.id}/`, profileInfo, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        console.log("response:", response);
        
 
        if (response.status === 200) {
          dispatch({ type: "UPDATE_USER", payload: response.data });
          setprofileUpdating(false);
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setprofileUpdating(false);
      });
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      [name]: value,
    }));
  };

  const handleImageDelete = (imageId) => {
    setImageIdToDelete(imageId);
    setOpen(true);
  };

  const handleClose = () => {
    setDeleteError(false);
    setDeleteSuccess(false);
    setOpen(false);
    setImageIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    console.log("imageIdToDelete:", imageIdToDelete);
    setLoading(true);
    await axios
      .delete(`${BackendUrl}/shop/shopImages/${parseInt(imageIdToDelete)}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        console.log("response:", response);
        if (response.status === 204) {
          setOpen(false);
          setImageIdToDelete(null);
          setDeleteSuccess(true);
          setProfileInfo((prevProfileInfo) => ({
            ...prevProfileInfo,
            images: prevProfileInfo.images.filter(
              (image) => image.id !== imageIdToDelete
            ),
          }));
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setDeleteError(true);
      });
    setLoading(false);
  };

  
  return (
    <Box>
      <Container sx={{ my: 15 }}>
        <ConfirmModel
          open={open}
          handleClose={handleClose}
          title="Delete Image"
          content="Are you sure you want to delete this image?"
          handleConfirm={handleConfirmDelete}
          loading={loading}
          deleteSuccess={deleteSuccess}
          deleteError={deleteError}
        />
        <Typography variant="h4" gutterBottom>
          Profile
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Shop Name"
          name="shop_name"
          value={profileInfo.shop_name}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Biography"
          name="biography"
          multiline
          rows={4}
          value={profileInfo.biography}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Website"
          name="website"
          value={profileInfo.website}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Street Address"
          name="street_address"
          value={profileInfo.street_address}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="City"
          name="city"
          value={profileInfo.city}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="province"
          name="province"
          value={profileInfo.province}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Zip Code"
          name="zip_code"
          value={profileInfo.zip_code}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Phone Number"
          name="phone"
          value={profileInfo.phone}
          onChange={handleInputChange}
          error={!!phoneError} // Set error state based on phoneError
          helperText={phoneError}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleProfileUpdate}
        >
          {profileUpdating ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            "Update Profile"
          )}
        </Button>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6" gutterBottom>
            Shop Images
          </Typography>
          <ImageGallery
            profileInfo={profileInfo}
            handleImageDelete={handleImageDelete}
          />
          <hr/>
          {/* <Typography variant="h6" gutterBottom>
            Upload Images
          </Typography>
          <Typography variant="body2" gutterBottom>
            You can upload multiple images at once
          </Typography>
          <Typography variant="body2" gutterBottom>
            Supported formats: jpeg, jpg, png
          </Typography> */}
          <Typography variant="body2" gutterBottom>
            Max file size: 5mb
          </Typography>
          <Typography variant="body2" gutterBottom>
            Recommended dimensions: 800x800
          </Typography>
          <Typography variant="body2" gutterBottom>
            Image quality: High
          </Typography>

          <Box
            style={{ display: "flex", justifyContent: "center", marginTop: 2 }}
          >
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              multiple
              onChange={(e) =>
                setImages((prevImages) => [...prevImages, ...e.target.files])
              }
              style={{ display: "none" }}
            />
            <label htmlFor="image">
              <Button variant="contained" component="span" color="secondary">
                Choose Images
              </Button>
            </label>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 8 }}
              onClick={handleUploadImages}
            >
              Upload Images
            </Button>
          </Box>
          {imageUploading && (
            <Box sx={{ width: "100%", m: 2 }}>
              <LinearProgress />
            </Box>
          )}

          {images.length > 0 && (
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
              {images.map((image, index) => (
                <Grid item key={index} xs={6} md={4} lg={3}>
                  <Card sx={{ position: "relative", height: "100%" }}>
                    <CardMedia
                      component="img"
                      image={URL.createObjectURL(image)}
                      alt="Selected Shop Image"
                      sx={{ objectFit: "cover", height: 150 }}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        backgroundColor: "rgba(255,255,255,0.8)",
                        borderRadius: "50%",
                      }}
                      onClick={() =>
                        setImages(images.filter((img, i) => i !== index))
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Profile;
