import { React, useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { Box, Container, Grid } from "@mui/material";
import NavbarComponent from "../Components/Navbar/NavbarComponent";
import Footer from "../Components/Footer/Footer";

const TermsOfServices = () => {
  return (
    <>
    <NavbarComponent/>
    <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
    <Container sx={{ my: 10 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3} style={{ borderRight: '2px solid #ccc', height: '100%', }}>
          <Sidebar />
        </Grid>
        <Grid item xs={12} md={9} lg={9}>
          <h1 className="text-4xl font-bold">Terms of Service</h1>
      <div className="font-serif basis-1/2 lg:py-0 lg:pl-8 md:pl-5 pl-0 md:py-0 py-8">
<p className="text-lg">
  These Terms of Service ("Terms") govern your access to and use of the
  services provided by Throttle Operations Inc. ("Throttle," "we," "us,"
  or "our"), including but not limited to our website and any related
  mobile applications (collectively, the "Services"). By accessing or
  using the Services, you agree to be bound by these Terms.
</p>
<div className="pl-4 italic">
  <p className="text-lg text-black">
    <strong>1. </strong> Acceptance of Terms By accessing or using the
    Services, you agree to these Terms, our Privacy Policy, and all
    applicable laws and regulations. If you do not agree with any of
    these terms, you are prohibited from using or accessing the
    Services.
  </p>
  <p className="text-lg text-black">
    <strong>2. </strong>Description of Services Throttle Operations Inc.
    provides a platform for searching and booking automotive services
    from a variety of automotive shops through our website. We
    facilitate the connection between users seeking automotive services
    ("Users") and automotive shops providing such services ("Shops").
  </p>
  <p className="text-lg text-black">
    <strong>3. </strong>
    Account Registration To access certain features of the Services, you
    may be required to create an account. You agree to provide accurate,
    current, and complete information during the registration process
    and to update such information to keep it accurate, current, and
    complete. You are responsible for maintaining the confidentiality of
    your account and password and for restricting access to your
    account.
  </p>
  <p className="text-lg text-black">
    <strong>4. </strong>
    Use of Services You agree to use the Services only for lawful
    purposes and in accordance with these Terms. You agree not to use
    the Services:
    <ul className="pl-5 text-lg text-neutral">
      <li>
        In any way that violates any applicable federal, state, local,
        or international law or regulation.
      </li>
      <li>
        To transmit, or procure the sending of, any advertising or
        promotional material without our prior written consent.
      </li>
      <li>
         To impersonate or attempt to impersonate Throttle Operations
        Inc., a Throttle employee, another user, or any other person or
        entity.
      </li>
      <li>
        To engage in any conduct that restricts or inhibits anyone's
        use or enjoyment of the Services, or which, as determined by us,
        may harm Throttle Operations Inc. or users of the Services, or
        expose them to liability.
      </li>
    </ul>
  </p>
  <p className="text-lg text-black">
    <strong>5. </strong>
    Intellectual Property The Services and their original content,
    features, and functionality are owned by Throttle Operations Inc.
    and are protected by international copyright, trademark, patent,
    trade secret, and other intellectual property or proprietary rights
    laws.
  </p>
  <p className="text-lg text-black">
    <strong>6. </strong>
    Privacy Your privacy is important to us. Our Privacy Policy explains
    how we collect, use, and disclose information about you. By using
    the Services, you consent to the collection and use of this
    information as described in the Privacy Policy.
  </p>
  <p className="text-lg text-black">
    <strong>7. </strong>
    Price Changes Disclaimer Please note that prices for automotive
    services listed on our platform are subject to change without
    notice. While we strive to provide accurate and up-to-date pricing
    information, mechanic shops may adjust their rates based on factors
    such as parts costs, labor fees, and market conditions. We recommend
    confirming the final price with the mechanic shop at the time of
    booking to avoid any misunderstandings.
  </p>
  <p className="text-lg text-black">
    <strong>8. </strong>
    Inadequate Services Disclaimer While we make every effort to ensure
    that the mechanic shops listed on our platform meet our quality
    standards, we cannot guarantee the quality or adequacy of services
    provided by individual shops. Mechanic shops operate independently,
    and their services may vary in terms of quality, timeliness, and
    customer satisfaction. We encourage users to share feedback about
    their experiences with mechanic shops to help us maintain a high
    level of service quality and address any concerns promptly.
  </p>
  <p className="text-lg text-black">
    <strong>9. </strong>
    Limitation of Liability In no event shall Throttle Operations Inc.,
    nor its directors, employees, partners, agents, suppliers, or
    affiliates, be liable for any indirect, incidental, special,
    consequential, or punitive damages, including without limitation,
    loss of profits, data, use, goodwill, or other intangible losses,
    resulting from
    <ul className="text-neutral">
      <li>
        (i) your access to or use of or inability to access or use the
        Services;
      </li>
      <li>
        (ii) any conduct or content of any third party on the Services;
      </li>
      <li>(iii) any content obtained from the Services; and</li>
      <li>
        (iv) unauthorized access, use, or alteration of your
        transmissions or content, whether based on warranty, contract,
        tort (including negligence), or any other legal theory, whether
        or not we have been informed of the possibility of such damage,
        and even if a remedy set forth herein is found to have failed of
        its essential purpose.
      </li>
    </ul>
  </p>
  <p className="text-lg text-black">
    <strong>10. </strong>
    Modification of Terms We reserve the right, at our sole discretion,
    to modify or replace these Terms at any time. If a revision is
    material, we will provide at least 30 days' notice prior to any new
    terms taking effect. What constitutes a material change will be
    determined at our sole discretion.
  </p>
  <p className="text-lg text-black">
    <strong>11. </strong>
    Governing Law These Terms shall be governed and construed in
    accordance with the laws of [Your Jurisdiction], without regard to
    its conflict of law provisions.{" "}
  </p>
  <p className="text-lg text-black">
    <strong>12. </strong>
    Contact Us If you have any questions about these Terms,
     <strong>  please contact us at{" "}
      <a href="mailto:info@throttleauto.ca">
      info@throttleauto.ca.
        </a>

        </strong>  By using the Services, you
    acknowledge that you have read, understood, and agree to be bound by
    these Terms.
  </p>
</div> 
</div>
</Grid>
</Grid>
</Container>
</Box>
<Footer/>
    </>
      
  );
};

export default TermsOfServices;









