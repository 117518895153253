import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import { BackendUrl } from '../../data/api';

const AvatarUpdate = ({ userId, token, onAvatarUpdate }) => {
  const [avatar, setAvatar] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  const handleUpload = () => {
    if (!avatar) return;

    const formData = new FormData();
    formData.append('avatar', avatar);

    setUploading(true);
    axios
      .put(`${BackendUrl}/shop/profile-picture/${userId}/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log('Avatar updated successfully:', response.data);
        setUploading(false);
        onAvatarUpdate(response.data.avatarUrl); 
      })
      .catch((error) => {
        console.error('Error updating avatar:', error);
        setUploading(false);
      });
  };

  return (
    <div>
      <input
        accept="image/*"
        type="file"
        onChange={handleAvatarChange}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={!avatar || uploading}
      >
        {uploading ? <CircularProgress size={24} /> : 'Upload'}
      </Button>
    </div>
  );
};

export default AvatarUpdate;
