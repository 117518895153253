import React, { useState, useMemo } from "react";
import {
  Button,
  TextField,
  Typography,
  Paper,
  Container,
  Autocomplete,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { servicesList, categorieList } from "../../data/serviceData";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_SERVICE_FAILURE } from "../../actions/shopActions";
import { CreateServices } from "../../actions/shopActions";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { BackendUrl } from "../../data/api";
import axios from "axios";

const AutoServices = () => {
  const { token } = useSelector((state) => state.auth);
  const { services, loading, error } = useSelector((state) => state.shop);
  const [descriptionLength, setDescriptionLength] = useState(0);

  const [selectedServiceForEdit, setSelectedServiceForEdit] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const dispatch = useDispatch();
  const [serviceCategory, setServiceCategory] = useState("");
  const [serviceSelected, setServicesSelected] = useState([]);
  const [allcars, setAllCars] = useState(true);
  const [serviceFields, setServiceFields] = useState({
    service_description: "",
    service_price: "",

  });

  const [car_year, setCar_year] = useState("")
  const [car_type, setCar_type] = useState("")
  const [car_make, setCar_make] = useState("")
  const [car_model, setCar_model] = useState("")
  const [TypeOptions, setTypeOptions] = useState([])

  const [alldata, setAllData] = useState([])

  const handleYearChange = async (event) => {
    setCar_year(event.target.value);
    if (event.target.value.length === 4 && !isNaN(event.target.value)) {
      const where = encodeURIComponent(JSON.stringify({
        "Year": parseInt(event.target.value)
      }));
      const response = await fetch(
        `https://parseapi.back4app.com/classes/Carmodels_Car_Model_List?count=1&where=${where}`,
        {
          headers: {
            'X-Parse-Application-Id': '7j9l7QNKl2GJG1Id02Jb0udd12troAFBoLwQmTbN',
            'X-Parse-REST-API-Key': 'ibUYfQuudctxsPF5gwGROa8rtudXMSDpAWf4VivC',
          }
        }
      );
      const data = await response.json();
      setAllData(data.results);
      if (data.results.length > 0) {
        setTypeOptions([...new Set(data.results.map((item) => item.Category))]);
      }
    }
  };

  const [MakeOptions, setMakeOptions] = useState([])

  const handleTypeChange = (event, value) => {
    setCar_type(value)
    setCar_make("")
    setCar_model("")
    const filteredCategoryOptionsBasedType = alldata.filter((item) => item.Category === value);
    setMakeOptions([...new Set(filteredCategoryOptionsBasedType.map((item) => item.Make))]);

  }

  const [ModelOptions, setModelOptions] = useState([])

  const handleCarMakeChange = (event, value) => {
    setCar_make(value)
    setCar_model("")
    const filteredCategoryOptionsBasedMake = alldata.filter((item) => item.Make === value);
    setModelOptions([...new Set(filteredCategoryOptionsBasedMake.map((item) => item.Model))]);

  }
  const [EditService, setEditService] = useState(false);
  const handleEditService = async (service) => {
    await setSelectedServiceForEdit(service);
    console.log("selectedServiceForEdit:", selectedServiceForEdit);
    setServiceCategory(service.service_category);
    setServicesSelected(service.service_name);
    setServiceFields({
      service_description: service.service_description,
      service_price: service.service_price,
      car_type: car_type,
      car_model: car_model,
      car_year: car_year,
      car_make: car_make,
    });
    setEditService(true);
    console.log("service:", service);
  };

  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handeupdatedService = async () => {
    setUpdateLoading(true);
    const data = {
      ...serviceFields,
      service_category: serviceCategory,
      service_name: serviceSelected,
    };
    const formData = new FormData();
    formData.append("service_name", data.service_name);
    formData.append("service_category", data.service_category);
    formData.append("service_description", data.service_description);
    formData.append("service_price", data.service_price);
    formData.append("car_type", data.car_type);
    formData.append("car_model", data.car_model);
    formData.append("car_year", data.car_year);
    formData.append("car_make", data.car_make);
    console.log("formData:", [...formData]);
    try {
      console.log("token:", token);
      console.log("formData:", [...formData]);
      await axios.put(
        `${BackendUrl}/shop/services/${selectedServiceForEdit.id}/`,
        formData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setEditService(false);
      setSelectedServiceForEdit(null);
      setServiceCategory("");
      setServicesSelected("");
      setServiceFields({
        service_description: "",
        service_price: "",
        car_type: "",
        car_model: "",
        car_year: "",
        car_make: "",
      });

      const newServices = services.map((service) =>
        service.id === selectedServiceForEdit.id
          ? { ...service, ...data }
          : service
      );
      dispatch({ type: "SET_SERVICES", payload: newServices });
    } catch (error) {
      console.log("error:", error);
      dispatch({ type: CREATE_SERVICE_FAILURE, payload: error });
    }

    setTimeout(() => {
      setUpdateLoading(false);
    }, 5000);
  };

  const handleCancelEdit = () => {
    setSelectedServiceForEdit(null);
    setServiceCategory("");
    setServicesSelected([]);
    setServiceFields({
      service_description: "",
      service_price: "",
      car_type: "",
      car_model: "",
      car_year: "",
      car_make: "",
    });
    setEditService(false);
  };

  const handleConfirmDelete = (service) => {
    setDeleteConfirmationOpen(true);
    setServiceToDelete(service);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationOpen(false);
    setServiceToDelete(null);
  };

  const handleDeleteConfirmed = async () => {
    setDeleteLoading(true);
    try {
      await axios.delete(`${BackendUrl}/shop/services/${serviceToDelete.id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      const newServices = services.filter(
        (service) => service.id !== serviceToDelete.id
      );
      dispatch({ type: "SET_SERVICES", payload: newServices });

      setServiceToDelete(null);
    } catch (error) {
      console.log("error:", error);
      dispatch({ type: CREATE_SERVICE_FAILURE, payload: error });
    }

    setTimeout(() => {
      setDeleteLoading(false);
    }, 1000);

    setDeleteConfirmationOpen(false);
  };

  const handleServiceFieldChange = (event) => {
    const { name, value } = event.target;

    setServiceFields({
      ...serviceFields,
      [name]: value,
    });
  };

  const handleAddService = async () => {

    if (allcars) {
      setCar_type("")
      setCar_make("")
      setCar_model("")
      setCar_year("")
    }

    const data = {
      ...serviceFields,
      service_category: serviceCategory,
      service_name: serviceSelected,
      car_make: car_make,
      car_model: car_model,
      car_year: car_year,
      car_type: car_type,

    };
    console.log("data:", data);
    const formData = new FormData();
    formData.append("service_name", data.service_name);
    formData.append("service_category", data.service_category);
    formData.append("service_description", data.service_description);
    formData.append("service_price", data.service_price);
    formData.append("car_type", data.car_type);
    formData.append("car_model", data.car_model);
    formData.append("car_year", data.car_year);
    formData.append("car_make", data.car_make);
    console.log("formData:", [...formData]);
    try {
      await dispatch(CreateServices(formData, token));
    } catch (error) {
      console.log("error:", error);
      dispatch({ type: CREATE_SERVICE_FAILURE, payload: error });
    }
  };
  const isFormValid =
    serviceSelected?.length > 0 &&
    serviceCategory !== "" &&
    serviceFields.service_price !== "";
  const memoizedOptions = useMemo(
    () => ({
      services: servicesList,
      categories: categorieList,
    }),
    [servicesList, categorieList]
  );

  return (
    <>
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: 'url("../assets/img/page-header.jpg")' }}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Services</h2>
                <p>Add , delete , remove services </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container>
        <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
          <DialogTitle>
            Are you sure you want to delete this service?
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {deleteLoading ? (
                <CircularProgress color="secondary" size={24} />
              ) : null}
            </Box>
          </DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: 2,
            }}
          >
            <Button
              onClick={handleCancelDelete}
              color="primary"
              variant="contained"
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteConfirmed}
              color="secondary"
              variant="contained"
            >
              Delete
            </Button>
          </Box>
        </Dialog>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper style={{ padding: 20 }}>
              <Typography variant="h5" gutterBottom>
                Available Services
              </Typography>
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                services.map((service, index) => {
                  return (
                    <Accordion key={index}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">
                          {service.service_name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <Typography variant="body1" gutterBottom>
                            {service.service_description}
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            Price: {service.service_price}$
                          </Typography>
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ color: "#0a0d13", fontWeight: "bold" }}
                          >
                            Category: {service.service_category}
                          </Typography>
                          {service.car_type ? (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", padding: 2 }}
                            >
                              <Typography variant="body1" gutterBottom>
                                Car Type: {service.car_type}
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                Car Model: {service.car_model}
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                Car Year: {service.car_year}
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                Car Make: {service.car_make}
                              </Typography>
                            </Box>
                          ) : (
                            <Chip
                              label="All Cars"
                              size="small"
                              style={{
                                backgroundColor: "#0a0d13",
                                color: "white",
                                fontWeight: "bold",
                                marginRight: "5px",
                                marginTop: "10px",
                              }}
                            />
                          )}
                          <Button
                            style={{
                              fontWeight: "bold",
                              marginRight: "5px",
                              marginTop: "10px",
                            }}
                            onClick={() => handleEditService(service)}
                          >
                            Edit
                          </Button>
                          <Button
                            style={{
                              fontWeight: "bold",
                              marginRight: "5px",
                              marginTop: "10px",
                            }}
                            onClick={() => handleConfirmDelete(service)}
                          >
                            Delete
                          </Button>
                      </AccordionDetails>
                    </Accordion>

                  );
                })
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={{ padding: 20 }}>
              <Typography variant="h5" gutterBottom>
                Add New Service
              </Typography>
              <Autocomplete
                label="Service Name"
                variant="outlined"
                name="service_name"
                value={serviceSelected}
                onChange={(event, value) => setServicesSelected(value)}
                style={{ marginBottom: "10px", marginTop: "30px" }}
                options={memoizedOptions.services}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Service Name"
                    variant="outlined"
                  />
                )}
              />
              <Autocomplete
                label="Service Category"
                variant="outlined"
                value={serviceCategory}
                onChange={(event, value) => setServiceCategory(value)}
                style={{ marginBottom: "10px", marginTop: "10px" }}
                options={memoizedOptions.categories}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Service Category"
                    variant="outlined"
                  />
                )}
              />
              <TextField
                label="Service Description"
                variant="outlined"
                name="service_description"
                value={serviceFields.service_description}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length <= 300) {
                    setServiceFields({
                      ...serviceFields,
                      service_description: value,
                    });
                    setDescriptionLength(value.length);
                  }
                }}
                style={{ marginBottom: "10px" }}
                fullWidth
                multiline
                rows={3}
                helperText={`${descriptionLength}/300 words`}
              />
              <FormControl fullWidth sx={{ mt: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">
                  Amount (before taxes)
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  label="Amount (before taxes)"
                  variant="outlined"
                  name="service_price"
                  value={serviceFields.service_price}
                  onChange={handleServiceFieldChange}
                  type="number"
                  style={{ marginBottom: "10px" }}
                />
                <Typography variant="caption" color="textSecondary">
                  Please enter the service price before taxes.
                </Typography>
              </FormControl>

              <FormControlLabel style={{ marginTop: "5px" }}
                control={
                  <Checkbox
                    checked={allcars}
                    onChange={(e) => {
                      setAllCars(e.target.checked);
                      if (e.target.checked) {
                        setServiceFields({
                          ...serviceFields,
                          car_type: "",
                          car_make: "",
                          car_model: "",
                          car_year: "",

                        });
                      }
                    }}
                  />
                }
                label="All Cars"
              />
              <br />

              {allcars ? null : (
                <div>
                  <TextField
                    label="Car Year"
                    variant="outlined"
                    name="car_year"
                    fullWidth
                    value={car_year}
                    onChange={handleYearChange}
                    style={{ marginBottom: "10px" }}
                  />
                  <Autocomplete
                    label="Car Type"
                    variant="outlined"
                    name="car_type"
                    fullWidth
                    value={car_type}
                    options={TypeOptions}
                    onChange={handleTypeChange}
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Car Type"
                        variant="outlined"
                      />
                    )}
                    style={{ marginBottom: "10px" }}
                  />
                  <Autocomplete
                    label="Car Make"
                    variant="outlined"
                    name="car_make"
                    fullWidth
                    value={car_make}
                    options={MakeOptions}
                    onChange={handleCarMakeChange}
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Car Make"
                        variant="outlined"
                      />
                    )}
                    style={{ marginBottom: "10px" }}
                  />
                  <Autocomplete
                    label="Car Model"
                    variant="outlined"
                    name="car_model"
                    fullWidth
                    value={car_model}
                    onChange={(event, value) => {
                      setCar_model(value);
                    }}
                    options={ModelOptions}
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Car Model"
                        variant="outlined"
                      />
                    )}
                    style={{ marginBottom: "10px" }}
                  />

                </div>
              )}
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={EditService ? handeupdatedService : handleAddService}
                style={{ width: "150px" }}
                disabled={!isFormValid}
              >
                {EditService ? (
                  updateLoading ? (
                    <CircularProgress color="secondary" size={24} />
                  ) : (
                    "Update Service"
                  )
                ) : (
                  "Add Service"
                )}
              </Button>

              {EditService ? (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ marginLeft: "10px", width: "150px" }}
                  onClick={handleCancelEdit}
                >
                  Cancel
                </Button>
              ) : null}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AutoServices;
