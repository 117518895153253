 
  const initialState = {
    searchInput : '',
    location : '',
    searchResults: [],
    loading: false,
    error: null,
    page_number : 0,
    total_pages : 0,
    total_results : 0,
    inputCategory : '',
    inputService : '',
  };

    const reducer = (state = initialState, action) => {
        switch (action.type) {
            case 'SET_SEARCH_INPUT':
            return {
                ...state,
                searchInput: action.payload,
            };
            case 'SEARCH_SUCCESS':
            return {
                ...state,
                searchResults: action.payload.results,
                total_results: action.payload.total_results,
                error: null,
                loading: false,
            };
            case 'SET_LOCATION':
            return {
                ...state,
                location: action.payload,
            };
            case 'SEARCH_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
            case 'SEARCH_FAIL':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
            case 'SET_CATEGORY':
            return {
                ...state,
                inputCategory: action.payload,
            };
            case 'SET_SERVICE':
            return {
                ...state,
                inputService: action.payload,
            };
            
            default:
            return state;
        }
        };

export default reducer;

  