import React, { useState } from 'react';
import NavbarComponent from '../Components/Navbar/NavbarComponent';
import ContactComponent from '../Components/ContactComponent';


const ContactPage = () => {
 
  return (
    <main id="main">
      <NavbarComponent />
      <ContactComponent/>
    </main>
  );
};

export default ContactPage;
