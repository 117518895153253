import React from 'react'
import NavbarComponent from '../Components/Navbar/NavbarComponent'
import Home from '../Components/Home/Home'
import Footer from '../Components/Footer/Footer'
import MainHome from '../Components/NavbarComponets/MainHome'


import {useDispatch  , useSelector} from 'react-redux';
import { useEffect } from 'react';
import { LoadUser } from '../actions/authActions'

const HomePage = () => {

  const dispatch = useDispatch()

  // const loadApp = async () => {
  //   const token = localStorage.getItem('token');
  //   if(token){
  //     dispatch(LoadUser(token));
  //   }
  // }
  // useEffect(() => {
  //   loadApp();
  // },[])
  


  return (
    <div>
     <NavbarComponent/>
      <MainHome/>
    </div>
  )
}
export default HomePage
