import React, { useState, useRef, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import PlacesAutocomplete from "../Components/PlacesAutocomplete";
import ImageUpload from "../Components/ImageUpload";
import CustomizedAlerts from "../Components/CustomizedAlerts";
import { useDispatch, useSelector } from "react-redux";
import { RegisterShop } from "../actions/authActions";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import NavbarComponent from "../Components/Navbar/NavbarComponent";
import Footer from "../Components/Footer/Footer";

function ListShop() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, error, user, token } = useSelector((state) => state.auth);
  const ref = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false); // New state for password validation
  const [email, setEmail] = useState("");
  const [profilePictures, setProfilePictures] = useState([]);
  const [address, setAddress] = useState("");
  const [showAlert, setshowAlert] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null });

  useEffect(() => {
    ref.current?.setAddressText("Some Text");

    const getLocation = () => {
      if (navigator.geolocation) {
           navigator.geolocation.getCurrentPosition(async (position) => {
              setUserLocation({
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude
              });
              
          }, (error) => {
              console.error("Error getting user's location:", error);
          });

   
      } else {
          console.error("Geolocation is not supported by this browser.");
      }
  };

  getLocation();

  }, []);

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, token, navigate]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfilePictures([...profilePictures, file]);
  };

  const removeProfilePicture = (index) => {
    const newProfilePictures = [...profilePictures];
    newProfilePictures.splice(index, 1);
    setProfilePictures(newProfilePictures);
  };

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailPattern.test(inputValue));
  };

  const onSubmit = async (data) => {
    if (Object.keys(errors).length === 0 && !emailError && !passwordError) {
      const formData = new FormData();
      formData.append("email", data.email);
      formData.append("shop_name", data.shopname);
      formData.append("phone", data.phonenumber);
      formData.append("biography", data.biography);
      formData.append("website", data.website);
      formData.append("password", data.password);
      formData.append("street_address", address.street);
      formData.append("province", address.province);
      formData.append("city", address.city);
      formData.append("zip_code", address.zip);
      formData.append("latitude", userLocation.latitude);
      formData.append("longitude", userLocation.longitude);
      profilePictures.forEach((picture, index) => {
        formData.append(`shop${index}`, picture);
      });

      try {
        await dispatch(RegisterShop(formData));
        reset();
        setProfilePictures([]);
        setAddress("");
        setEmail("");
        setSucess(true);
      } catch (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          await dispatch({
            type: "FAILURE",
            payload: { detail: "Network Error" },
          });
        } else {
          await dispatch({ type: "FAILURE", payload: error?.response?.data });
        }
      }
    } else {
      setshowAlert(true);
    }
  };

  const handleAddressChange = (selectedAddress) => {
    setAddress(selectedAddress);
  };

  // Custom validation rule for phone number length
  useEffect(() => {
    register("phonenumber", {
      required: true,
      minLength: { value: 10, message: "Mobile number should be at least 10 digits" },
      maxLength: { value: 10, message: "Mobile number should be at most 10 digits" },
      pattern: {
        value: /^[0-9]+$/,
        message: "Mobile number should contain only digits",
      },
    });
  }, [register]);

  // Custom validation rule for password length
  useEffect(() => {
    register("password", {
      required: true,
      minLength: { value: 8, message: "Password should be at least 8 characters long" },
    });
  }, [register]);

  return (
    <Box>
      <NavbarComponent />
      <Container component="main" sx={{ my: 15 }}>
        <CssBaseline />
        {error && (
          <CustomizedAlerts
            title="Error"
            message={error.detail}
            severity="error"
            onClose={() => dispatch({ type: "CLEAR_ERROR" })}
          />
        )}
        {sucess && (
          <CustomizedAlerts
            title="Success"
            message={`Your Shop has been created successfully. Please check your email for verification link`}
            severity="success"
            onClose={() => setSucess(false)}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          {isLoading ? (
            <Box sx={{ mt: 5, mb: 5 }}>
              {/* <Loader /> */}
              <CircularProgress />
            </Box>
          ) : (
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    {...register("shopname", { required: true })}
                    required
                    fullWidth
                    id="shopname"
                    label="Shop Name"
                    error={errors.shopname ? true : false}
                    helperText={errors.shopname ? "Shop Name is required" : ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Address
                  </Typography>
                </Grid>
                {showAlert && (
                  <CustomizedAlerts
                    title="Error"
                    message="Please fill in the address field to continue.."
                    severity="error"
                    onClose={() => setshowAlert(false)}
                  />
                )}

                <PlacesAutocomplete
                  handleAddressChange={handleAddressChange}
                  address={address}
                />

                {/* Contact Information */}
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Contact Information
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...register("phonenumber")}
                    required
                    fullWidth
                    type="number"
                    id="phonenumber"
                    label="Phone Number"
                    error={errors.phonenumber ? true : false}
                    helperText={errors.phonenumber ? errors.phonenumber.message : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email format",
                      },
                    })}
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    error={errors.email ? true : emailError}
                    helperText={
                      errors.email
                        ? "Email is required"
                        : emailError
                        ? "Invalid email format"
                        : ""
                    }
                    onChange={handleEmailChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...register("password", { required: true })}
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    type="password"
                    error={errors.password ? true : passwordError}
                    helperText={
                      errors.password
                        ? "Password is required"
                        : passwordError
                        ? "Password should be at least 8 characters long"
                        : ""
                    }
                    onChange={(e) => {
                      // Check password length
                      setPasswordError(e.target.value.length < 8);
                      // Update password value
                      register("password").onChange(e);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Additional Information
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    {...register("biography", { required: true })}
                    required
                    fullWidth
                    multiline
                    minRows={3}
                    id="biography"
                    label="Biography"
                    error={errors.biography ? true : false}
                    helperText={errors.biography ? "Biography is required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...register("website" , {
                      required : false,
                    }
                    )}
                 
                    fullWidth
                    id="website"
                    label="Website"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ImageUpload
                    profilePictures={profilePictures}
                    handleImageChange={handleImageChange}
                    removeProfilePicture={removeProfilePicture}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={
                  !address.street ||
                  !address.province ||
                  !address.city ||
                  !address.zip ||
                  passwordError // Disable button if password error
                }
              >
                Register
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item mb={2}>
                  <Link href="/login" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
      <Footer />
    </Box>
  );
}

export default ListShop;
