import React from "react";
import { Link  , useNavigate , useLocation} from 'react-router-dom';

import { Button } from "react-bootstrap"

import { useDispatch , useSelector } from 'react-redux';
import {Logout } from '../../actions/authActions';
import {
  Box 
} from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';

const NavbarComponent = () => {
  const dispatch =  useDispatch()
  const navigate =  useNavigate()
  const { user  ,  token} = useSelector((state) => state.auth);
  const location = useLocation();
  const [show, setShow] = React.useState(false);
  const [loading ,  setloading ] =  React.useState(false)

  const handlelogout = async() => {
    setloading(true)
    console.log('logging out' , token);
    try{
      await dispatch(Logout(token));
      setloading(false)
      navigate('/');
      window.location.reload();
    }
    catch(e){
      console.log(e);
      alert('Error in logging out',e);
    }
    setloading(false)
  }

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <header id="header" className="header d-flex align-items-center fixed-top">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <Link to="/" className="logo d-flex align-items-center">
          <h1>Throttle</h1>
        </Link>
        <i
          className={
            show
              ? "mobile-nav-toggle mobile-nav-hide bi bi-x"
              : "mobile-nav-toggle mobile-nav-show bi bi-list"
          }
          onClick={handleShow}
        />
        <nav className={show ? "navbar" : "navbar d-none d-lg-block"}>
          <ul>
            <li>
              <Link to="/" className={location.pathname === "/" ? "active" : ""}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className={location.pathname === "/about" ? "active" : ""}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/services" className={location.pathname === "/services" ? "active" : ""}>
                Services
              </Link>
            </li>
            <li>
              <Link to="/contact" className={location.pathname === "/contact" ? "active" : ""}>
                Contact
              </Link>
            </li>
            <li>
              <Link to="/pricing" className={location.pathname === "/pricing" ? "active" : ""}>
                Pricing
              </Link>
            </li>
            
            <hr style={{ borderTop: '1px solid white', width: '80%', margin: '10px auto' }} />
            {user ? (
              user.user_type === "customer" ? (
                <>
                  <li>
                    <Link className={location.pathname === '/dashboard' ? 'active' : ''} to='/dashboard'>Dashboard</Link>
                  </li>
                  <li>
                    <Link className={location.pathname === '/dashboard/profile' ? 'active' : ''} to='/dashboard/profile'>Profile</Link>
                  </li>
                  <Link>
                  <Box sx={{ m: 1, position: 'relative' }}>
                  <Button

variant="danger"

disabled={loading}
onClick={() => handlelogout()}
style={{ 

  

}}
>
{
loading && ( <CircularProgress
  size={24}
  sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  }}
/>) 
}
logout
</Button>
</Box>
        
              </Link>
                </>
              ) : (
                <>
                  <li>
                    <Link className={location.pathname === '/dashboard' ? 'active' : ''} to="/dashboard">Dashboard</Link>
                  </li>
                  <li>
                    <Link className={location.pathname === '/dashboard/Services' ? 'active' : ''} to="/dashboard/Services">Services</Link>
                  </li>
                  <li>
                    <Link className={location.pathname === '/dashboard/appointment' ? 'active' : ''} to='/dashboard/appointment'>Appointments</Link>
                  </li>
                  <li>
                    <Link className={location.pathname === '/dashboard/profile' ? 'active' : ''} to='/dashboard/profile'>Profile</Link>
                  </li>
                  <Link>
                  <Box sx={{ m: 1, position: 'relative' }}>
                  <Button

variant="danger"

disabled={loading}
onClick={() => handlelogout()}
style={{ 

  

}}
>
{
loading && ( <CircularProgress
  size={24}
  sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  }}
/>) 
}
logout
</Button>
</Box>
        
              </Link>
                </>
              )
            ) : (
              <>
                <li>
                  <Link className={location.pathname === "/registershop" ? "active" : ""} to="/registershop">
                    Add your business
                  </Link>
                </li>
                <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Link className="get-a-quote" to="/login">
                    Login
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default NavbarComponent;
