import axios from 'axios';
import { BackendUrl } from '../data/api';

const searchInitialQuery = (searchInput) => {
    console.log('searchInput:', searchInput);
  return async (dispatch) => {
    dispatch({ type: 'SEARCH_REQUEST' });
    try {
      const response = await axios.get(`${BackendUrl}/shop/search/?query=${searchInput}`);
      console.log('response:', response.data);
      dispatch({ type: 'SEARCH_SUCCESS', payload: response.data });
    } catch (error) {
      console.error('Error occurred during search:', error);
      dispatch({ type: 'SEARCH_FAIL', payload: error.message });
    }
  };
};
export { searchInitialQuery};
