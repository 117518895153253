import React, { useState, useRef, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import PlacesAutocomplete from "../Components/PlacesAutocomplete";
import ImageUpload from "../Components/ImageUpload";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import { useDispatch, useSelector } from "react-redux";
import CustomizedAlerts from "../Components/CustomizedAlerts";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import CarModelForm from "../Components/CarModelForm";
import { RegisterUser } from "../actions/authActions";
import NavbarComponent from "../Components/Navbar/NavbarComponent";
import Footer from "../Components/Footer/Footer";

function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, error, user, token } = useSelector((state) => state.auth);

  const [sucess, setSucess] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, token, navigator]);


  const ref = useRef();
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [profilePictures, setProfilePictures] = useState([]);
  const [carYear, setCarYear] = useState("");
  const [carCategory, setCarCategory] = useState("");
  const [carModel, setCarModel] = useState("");
  const [carMake, setCarMake] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [firstnameError, setFirstnameError] = useState(false);


  const handleCarModelSubmit = (data) => {
    console.log(data);
    setCarYear(data.year);
    setCarCategory(data.category);
    setCarModel(data.model);
    setCarMake(data.make);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfilePictures([...profilePictures, file]);
  };

  const removeProfilePicture = (index) => {
    const newProfilePictures = [...profilePictures];
    newProfilePictures.splice(index, 1);
    setProfilePictures(newProfilePictures);
  };
  const handleAddressChange = (selectedAddress) => {
    setAddress(selectedAddress);
  };

  useEffect(() => {
    ref.current?.setAddressText("Some Text");
  }, []);

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailPattern.test(inputValue);
    const isGmail = inputValue.endsWith("@gmail.com");

    setEmailError(!emailPattern.test(inputValue));
  };
  const onSubmit = async (event) => {
    event.preventDefault();
    console.log("form data",firstName,lastName,email,password,address,phoneNumber,carCategory,carMake,carModel,carYear,profilePictures[0]);
    const formData = new FormData();
    if (!emailError && !passwordError && !firstnameError){
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("street_address", address.street);
      formData.append("city", address.city);
      formData.append("province", address.province);
      formData.append("zip_code", address.zip);
      formData.append("phone", phoneNumber);
      formData.append("Car_type", carCategory);
      formData.append("Car_make", carMake);
      formData.append("Car_model", carModel);
      formData.append("Car_year", carYear);
      formData.append("profilePicture", profilePictures[0]);

      try {
        await dispatch(RegisterUser(formData));
        console.log("form data", [...formData]);
        setSucess(true);
      } catch (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          await dispatch({
            type: "FAILURE",
            payload: { detail: "Network Error" },
          });
        } else {
          await dispatch({ type: "FAILURE", payload: error?.response?.data });
        }
      }
    }
  };

  const validatePhoneNumber = () => {
    const phoneRegex = /^\d{10}$/; 
    return phoneRegex.test(phoneNumber);
  };

  const handlePasswordChange = (event) => {
    const inputValue = event.target.value;
    setPassword(inputValue);

    if (inputValue.length < 8) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  return (
    <Box>
      <NavbarComponent />

      <Container component="main" sx={{ my: 15 }}>
        <CssBaseline />
        {error && (
          <CustomizedAlerts
            title="Error, Something went wrong"
            message={error?.detail}
            severity="error"
            onClose={() => dispatch({ type: "CLEAR_ERROR" })}
          />
        )}
        {sucess && (
          <>
            <CustomizedAlerts
              title="Success"
              message={`
           Account has been created! 
            Please check your email ${email} to verify your account
            .`}
              severity="success"
              onClose={() => setSucess(false)}
            />
          </>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "blue" }}>
            <ContactEmergencyIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>

          {isLoading ? (
            <Box sx={{ mt: 5, mb: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box component="form" onSubmit={onSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    onChange={(e) =>{
                    setFirstName(e.target.value)
                    setFirstnameError(false) 
                  }}
                    error={firstnameError}
                    helperText={firstnameError ? "First Name is required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    onChange={(e) => setLastName(e.target.value)}
                    
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    error={emailError}
                    helperText={emailError ? "Invalid email format" : ""}
                    onChange={handleEmailChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    type="password"
                    id="password"
                    label="Password"
                    name="password"
                    error={passwordError}
                    helperText={
                      passwordError
                        ? "Password must be at least 8 characters"
                        : ""
                    }
                    onChange={handlePasswordChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Address
                  </Typography>
                </Grid>
                <PlacesAutocomplete
                  handleAddressChange={handleAddressChange}
                  address={address}
                />
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="phoneNumber"
                    label="Phone Number (Optional)"
                    name="phoneNumber"
                    error={ phoneNumber.length > 0 && !validatePhoneNumber() }
                    helperText={
                      !validatePhoneNumber()
                        ? "Phone number should be exactly 10 digits"
                        : ""
                    }
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} 
                style={
                  {
                    display: "flex",
                    marginBottom: "20px"
                  
                  }
                }
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold"  }}>
                    Vehicle Information
                  </Typography>
                </Grid>
                <CarModelForm onChange={handleCarModelSubmit} />
                <Grid item xs={12}>
                  <ImageUpload
                    profilePictures={profilePictures}
                    handleImageChange={handleImageChange}
                    removeProfilePicture={removeProfilePicture}
                    avatar={true}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Register
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item mb={2}>
                  <Link href="/login" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
      <Footer />
    </Box>
  );
}

export default Register;
