import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

import Container from "@mui/material/Container";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  IconButton,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import dayjs from "dayjs";

import {
  fetchAppointmentsAction,
  acceptAppointmentAction,
  rejectAppointmentAction,
  updateAppointmentDateAction,
} from "../../actions/apointmentAction";
import { isoDateToDisplay } from "../../utils";
import UserShopDashboardTable from "../UserShopDashboardTable";

export const CustomCard = ({ children }) => (
  <Card
    variant="outlined"
    style={{
      marginBottom: "1rem",
      padding: "1rem",
      background: "#0e1d34",
      color: "white",
    }}
  >
    <CardContent>{children}</CardContent>
  </Card>
);

const ShopHome = () => {
  const { appointments, loading, error } = useSelector(
    (state) => state.apointment
  );
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  console.log(user);
  useEffect(() => {
    dispatch(fetchAppointmentsAction());
  }, [dispatch]);

  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [acceptedAppointments, setAcceptedAppointments] = useState([]);

  useEffect(() => {
    const pending = appointments.filter(
      (appointment) =>
        appointment.status === "Pending" &&
        new Date(appointment.service_date) >= new Date()
    );
    const accepted = appointments.filter(
      (appointment) =>
        appointment.status === "Approved" &&
        new Date(appointment.service_date) >= new Date()
    );
    setPendingAppointments(pending);
    setAcceptedAppointments(accepted);
  }, [appointments]);

  const [updatedAppointmentDates, setUpdatedAppointmentDates] = useState({});

  const handleAppointmentDateChange = (newValue, appointmentId) => {
    // Store the updated date locally
    setUpdatedAppointmentDates((prevState) => ({
      ...prevState,
      [appointmentId]: newValue,
    }));
  };

  const handleAccept = (appointmentId) => {
    console.log(updatedAppointmentDates[appointmentId]);
    console.log(appointmentId);
    dispatch(
      acceptAppointmentAction(
        appointmentId,
        updatedAppointmentDates[appointmentId]
      )
    );
    dispatch(fetchAppointmentsAction());
  };

  const handleReject = (appointmentId) => {
    dispatch(rejectAppointmentAction(appointmentId));
  };

  const parseDate = (isoDate) => {
    return dayjs(isoDate);
  };
  console.log(pendingAppointments);
  console.log(acceptedAppointments);
  return (
    <div>
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: 'url("assets/img/page-header.jpg")' }}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Welcome, {user.shop_name}</h2>
                <p>
                  Review, confirm, or reject your appointments on your dashboard
                </p>
                <Button variant="contained" color="primary">
                  <a href="http://billing.stripe.com/p/login/test_3cs6rhddNeVr9xu9AA" style={{color: "white"}}>
                  manage Subscription
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container sx={{ my: 2 }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Typography
              variant="h6"
              style={{
                marginLeft: "18px",
                marginTop: "40px",
                fontSize: "25px",
              }}
            >
              Upcoming Appointments
            </Typography>
            {pendingAppointments.length === 0 && (
              <Grid item xs={12}>
                <CustomCard>
                  <Typography variant="body1">
                    No Upcoming Appointments
                  </Typography>
                </CustomCard>
              </Grid>
            )}

            {pendingAppointments.length === 0 ? null : (
              <Grid item xs={12}>
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <table
                    style={{
                      width: "fit-content",
                      borderCollapse: "collapse",
                    }}
                  >
                    <thead>
                      <tr
                        style={{ backgroundColor: "#001973", color: "white" }}
                      >
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "120px",
                          }}
                        >
                          Client
                        </th>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "150px",
                          }}
                        >
                          Service
                        </th>

                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "200px",
                          }}
                        >
                          Car Information
                        </th>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "120px",
                          }}
                        >
                          Price
                        </th>

                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "120px",
                          }}
                        >
                          Client Contact
                        </th>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "170px",
                          }}
                        >
                          Date
                        </th>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "80px",
                          }}
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendingAppointments.map((appointment) => (
                        <tr key={appointment.id}>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {appointment.customer.first_name}{" "}
                            {appointment.customer.last_name}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {appointment.service.service_name}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            Car Make - {appointment.service.car_make || "NA"}{" "}<br /> 
                            Car Model - {appointment.service.car_model ||"NA"}{" "}<br />
                            Car Type - {appointment.service.car_type ||"NA"}{" "}<br />
                            Car Year - {appointment.service.car_year || "NA"}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            ${appointment.service.service_price ?? "NA"}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {appointment.customer.phone ?? "NA"}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {isoDateToDisplay(appointment.service_date)}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              color:
                                appointment.status === "Pending"
                                  ? "#F4B400"
                                  : "#4CAF50",
                            }}
                          >
                            {appointment.status}
                          </td>
                          <td>
                            
                            <div className="d-flex">
                              <Button
                                style={{
                                  margin: "8px",
                                }}
                                onClick={() => handleAccept(appointment.id)}
                                variant="contained"
                                color="primary"
                              >
                                Accept
                              </Button>
                              <Button
                                onClick={() => handleReject(appointment.id)}
                                variant="contained"
                                color="secondary"
                                style={{
                                  margin: "8px",
                                }}
                              >
                                Reject
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Grid>
            )}

            {/* {pendingAppointments.map((appointment) => (
              <Grid item xs={12} key={appointment.id}>
                <CustomCard>
                  <Typography variant="body1">
                    {appointment.service.service_name} -
                    {isoDateToDisplay(appointment.service_date)} -{" "}
                    {appointment.customer.first_name}{" "}
                    {appointment.customer.last_name}
                  </Typography>
                </CustomCard>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box sx={{ display: "flex", alignItems: "center", m: 2 }}>
                    <DateTimePicker
                      label="Appointment Date and Time"
                      value={parseDate(appointment.service_date)}
                      onChange={(newValue) =>
                        handleAppointmentDateChange(newValue, appointment.id)
                      }
                      renderInput={(params) => (
                        <Box>
                          <IconButton>
                            <FontAwesomeIcon icon={faCalendarPlus} />
                          </IconButton>
                          <input {...params.inputProps} />
                        </Box>
                      )}
                    />
                  </Box>
                </LocalizationProvider>

                <Button
                  style={{
                    margin: "1rem",
                  }}
                  onClick={() => handleAccept(appointment.id)}
                  variant="contained"
                  color="primary"
                >
                  Accept
                </Button>
                <Button
                  onClick={() => handleReject(appointment.id)}
                  variant="contained"
                  color="secondary"
                >
                  Reject
                </Button>
              </Grid>
            ))} */}
          </Grid>
        )}

        <Typography
          variant="h6"
          style={{ marginTop: "5px", marginBottom: "15px", fontSize: "25px" }}
        >
          Scheduled Appointments
        </Typography>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {acceptedAppointments.length === 0 && (
              <Grid item xs={12}>
                <CustomCard>
                  <Typography variant="body1">
                    No Scheduled Appointments
                  </Typography>
                </CustomCard>
              </Grid>
            )}
           
              <Grid item xs={12} >
                <table
                    style={{
                      width: "fit-content",
                      borderCollapse: "collapse",
                    }}
                  >
                    <thead>
                      <tr
                        style={{ backgroundColor: "#001973", color: "white", width: "100%" }}
                      >
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "120px",
                          }}
                        >
                          Client
                        </th>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "150px",
                          }}
                        >
                          Service
                        </th>

                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "200px",
                          }}
                        >
                          Car Information
                        </th>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "120px",
                          }}
                        >
                          Price
                        </th>

                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "120px",
                          }}
                        >
                          Client Contact
                        </th>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "170px",
                          }}
                        >
                          Date
                        </th>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "80px",
                          }}
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                       {acceptedAppointments.map((appointment) => (
                        <tr key={appointment.id}>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {appointment.customer.first_name}{" "}
                            {appointment.customer.last_name}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {appointment.service.service_name}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            Car Make - {appointment.service.car_make || "NA"}{" "}<br /> 
                            Car Model - {appointment.service.car_model ||"NA"}{" "}<br />
                            Car Type - {appointment.service.car_type ||"NA"}{" "}<br />
                            Car Year - {appointment.service.car_year || "NA"}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            ${appointment.service.service_price ?? "NA"}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {appointment.customer.phone ?? "NA"}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {isoDateToDisplay(appointment.service_date)}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              color:
                                appointment.status === "Pending"
                                  ? "#F4B400"
                                  : "#4CAF50",
                            }}
                          >
                            {appointment.status}
                          </td>
                          <td>
                            
                          </td>
                        </tr>
     ))} 
                    </tbody>
                  </table>
              </Grid>
     
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default ShopHome;
