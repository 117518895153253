import React from 'react';
import { Navigate ,Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({children}) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  if (!isAuthenticated) {
    // If the user is not authenticated, redirect them to the /login page
    return <Navigate to="/login" replace />;
  }

  // If the user is authenticated, render the component
  // return <Component {...rest} />;
  // return <Outlet />;
  return children;

};

export default ProtectedRoute;

