import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, Typography, TextField, Grid, Paper, CircularProgress } from "@mui/material";

import ApiService from "../../data/payment";

import { useNavigate } from "react-router-dom";


const CheckoutForm = ({ data }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Introduce loading state
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  // Handle real-time validation errors from the CardElement.
  const handleChange = (event) => {
    setError(event.error ? event.error.message : null);
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading state to true during submission
    const card = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: card
    });

    if (error) {
      setError(error.message);
      setLoading(false); // Reset loading state if there's an error
    } else {
      setError(null);
      // call the API to create payment intent
      ApiService.saveStripeInfo({
        email: data.email,
        payment_method_id: paymentMethod.id,
        plan: data.plan,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.message == 'Success') {
          alert('Payment successful!');
          navigate('/dashboard');
        }
        else {
          alert(response.data.data.extra_msg );
        setLoading(false); // Reset loading state after successful submission
        }
      })
      .catch((error) => {
        console.error(error);
        alert('Payment failed! Please try again.');
        setLoading(false); // Reset loading state if there's an error
      });
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <Typography variant="h6">Customer Details</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Shop Name"
            variant="outlined"
            value={data.name}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            variant="outlined"
            value={data.email}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Phone"
            variant="outlined"
            value={data.phone}
            fullWidth
            disabled
          />
        </Grid>
        {/* plan and price  */}

        <Grid item xs={12} sm={6}>
            <TextField
                label="Plan"
                variant="outlined"
                value={data.plan}
                fullWidth
                disabled
            />

        </Grid>

        <Grid item xs={12} sm={6}>
            <TextField
                label="Price"
                variant="outlined"
                value={data.price +" CAD"}
                fullWidth
                disabled
            />
            </Grid>


      </Grid>
      <Typography variant="h6" sx={{ marginTop: 2 }}>
        Payment Details
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
              onChange={handleChange}
            />
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Pay Now'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default CheckoutForm;
