import React, { useState } from 'react';
import { TextField, Button, Snackbar, Typography, Container, Box } from '@mui/material';
import axios from 'axios';
import NavbarComponent from '../Components/Navbar/NavbarComponent';
import Footer from '../Components/Footer/Footer';
import { BackendUrl } from '../data/api';
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${BackendUrl}/accounts/password/reset/`, { email });
      setSuccess(true);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };
  const handleCloseSnackbar = () => {
    setError(null);
    setSuccess(false);
  };
  return (
    <Box>
      <NavbarComponent/>
    <Container maxWidth="xs"   sx={{ my: 15 }}>
      <Box mt={8}  mb={5}>
        <Typography variant="h5" align="center" gutterBottom>
          Forgot Your Password?
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            type="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            fullWidth
            required
            disabled={loading}
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            size="large"
          >
            {loading ? 'Submitting...' : 'Submit'}
          </Button>
        </form>
        <Snackbar
          open={error !== null || success}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={error || 'Password reset email sent successfully.'}
        />
      </Box>
    </Container>
    <Footer/>
    </Box>
  );
};

export default ForgotPassword;
