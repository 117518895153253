
const Footer = () => {
  return (
    <>
    <footer id="footer" className="footer">
  <div className="container">
    <div className="row gy-4">
      <div className="col-lg-5 col-md-12 footer-info">
        <a href="/" className="logo d-flex align-items-center">
          <span>Throttle</span>
        </a>
        <p>Revolutionizing automobile servicing with transparency and trust, redefining the industry standard for a confident and informed automotive experience.</p>
        <div className="social-links d-flex mt-4">
          <a href="#" className="twitter"><i className="bi bi-twitter" /></a>
          <a href="#" className="facebook"><i className="bi bi-facebook" /></a>
          <a href="#" className="instagram"><i className="bi bi-instagram" /></a>
          <a href="#" className="linkedin"><i className="bi bi-linkedin" /></a>
        </div>
      </div>
      <div className="col-lg-2 col-6 footer-links">
        <h4>Useful Links</h4>
        <ul>
          <li><a href="#">Home</a></li>
          <li><a href="/about">About Us</a></li>
          <li><a href="/services">Services</a></li>
          <li><a href="/terms">Terms of Services</a></li>
          <li><a href="/privacy">Privacy Policy</a></li>
        </ul>
      </div>
      <div className="col-lg-2 col-6 footer-links">
        <h4>Popular Services</h4>
        <ul>
          <li><a href="/services">Repair</a></li>
          <li><a href="/services">Car Cleaning </a></li>
          <li><a href="/services">Tire Change</a></li>
        </ul>
      </div>
      <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
        <h4>Contact Us</h4>
        <p>
          {/* A108 Adam Street <br /> */}
          {/* New York, NY 535022<br /> */}
          {/* United States <br /><br /> */}
          {/* <strong>Phone:</strong> +1 5589 55488 55<br /> */}
          <strong>Email:</strong>
          <a
          className="link-primary"
           href="mailto:info@throttleauto.ca"
            > info@throttleauto.ca
          </a>
          <br />
        </p>
      </div>
    </div>
  </div>
  <div className="container mt-4">
    <div className="copyright">
      © Copyright <strong><span>Throttle</span></strong>. All Rights Reserved
    </div>
  </div>
</footer>
<a href="#" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

</>

  );
};

export default(Footer);

