
const initialState = {
    appointments: [],
    loading: false,
    error: null,
    newAppointment: null,
    buttonLoading: false,
};
const appointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'APPOINTMENT_REQUEST':
            return {
                ...state,
                buttonLoading: true,
                error: null,
            };
        case 'APPOINTMENT_SUCCESS':
            return {
                ...state,
                buttonLoading: false,
                appointments: [...state.appointments, action.payload],
                error: null,
            };
        case 'APPOINTMENT_FAIL':
            return {
                ...state,
                buttonLoading: false,
                error: action.payload,
            };
        case 'NEW_APPOINTMENT':
            return {
                ...state,
                newAppointment: action.payload,
            };
        case 'FETCH_APPOINTMENTS_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'FETCH_APPOINTMENTS_SUCCESS':
            return {
                ...state,
                loading: false,
                appointments: action.payload,
                error: null,
            };
        case 'FETCH_APPOINTMENTS_FAIL':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
            case 'APPOINTMENT_ACCEPT_REQUEST':
                case 'APPOINTMENT_REJECT_REQUEST':
                case 'APPOINTMENT_UPDATE_REQUEST':
                  return {
                    ...state,
                    loading: true,
                    error: null,
                  };
                case 'APPOINTMENT_ACCEPT_SUCCESS':
                  return {
                    ...state,
                    loading: false,
                    appointments: state.appointments.map(appointment =>
                      appointment.id === action.payload ? { ...appointment, status: 'Approved' } : appointment
                    ),
                    error: null,
                  };
                case 'APPOINTMENT_REJECT_SUCCESS':
                  return {
                    ...state,
                    loading: false,
                    appointments: state.appointments.filter(appointment => appointment.id !== action.payload),
                    error: null,
                  };
                case 'APPOINTMENT_UPDATE_SUCCESS':
                  return {
                    ...state,
                    loading: false,
                    appointments: state.appointments.map(appointment =>
                      appointment.id === action.payload.appointmentId ? { ...appointment, service_date: action.payload.newDate } : appointment
                    ),
                    error: null,
                  };            
        default:
            return state;
    }
}


export default appointmentReducer;