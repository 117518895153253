// export const  isoDateToDisplay = (isoDate) => {
//     const date = new Date(isoDate);
//     return date.toDateString();
//   }
  

  // export const isoDateToDisplay = (isoDate) => {
  //   const date = new Date(isoDate);
  //   const options = { 
  //     year: 'numeric', 
  //     month: 'short', 
  //     day: 'numeric', 
  //     hour: 'numeric', 
  //     minute: 'numeric', 
  //     hour12: true 
  //   };
  //   return date.toLocaleString('en-US', options);
  // }
  
  export const isoDateToDisplay = (isoDate) => {
    const date = new Date(isoDate);
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true,
      timeZone: 'America/New_York' // Set timezone to EST
    };
    return date.toLocaleString('en-US', options);
  };
  