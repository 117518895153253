export const servicesList = [
    "Oil Change",
    "Brake Inspection",
    "Tire Rotation",
    "Wheel Alignment",
    "Battery Replacement",
    "Cabin Air Filter Change",
    "Engine Air Filter Change",
    "Engine Tune-Up",
    "Transmission Repair",
    "Brake Repair",
    "Suspension Repair",
    "Exhaust System Repair",
    "AC System Check",
    "Fluid Level Check",
    "Belt and Hose Inspection",
    "Brake System Check",
    "Steering and Suspension Check",
    "Tire Installation",
    "Wheel Balancing",
    "Headlight Restoration",
    "Windshield Replacement",
    "Audio System Installation",
    "Diagnostic Services",
    "Emission Testing",
    "Air Conditioning Service",
    "Electrical System Repair",
    "Fuel System Cleaning"
];

export const categorieList = [
  "Maintenance",
  "Repair",
  "Inspection",
  "Installation",
  "Miscellaneous",
];
