import React from 'react'
import { Box, CircularProgress, Typography , Rating , Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { isoDateToDisplay } from '../../utils'
import { CustomCard } from './ShopHome'
import { BackendUrl } from '../../data/api'
import axios from 'axios'
import { Grid } from '@mui/material'
import {fetchAppointmentsAction} from '../../actions/apointmentAction'


const Appointment = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { appointments , loading, error } = useSelector((state) => state.apointment);

  const dateExpiredAndRejectedAppointments = appointments.filter(appointment => {
    const currentDate = new Date();
    const appointmentDate = new Date(appointment.service_date);
    return appointmentDate < currentDate || appointment.status === "Rejected";
  });
  
  
  const handleVerify = async(reviewId) => {
    await axios.put(`${BackendUrl}/booking/reviews/${reviewId}/`,{},{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      }
    })
    dispatch(fetchAppointmentsAction());
  };
  return (
    <>
  
    <div className="breadcrumbs">
    <div className="page-header d-flex align-items-center" style={{backgroundImage: 'url("assets/img/page-header.jpg")'}}>
      <div className="container position-relative">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-6 text-center">
            <h2>Apointment History</h2>
            <p>Verify the Reviews  </p> 
          </div>
        </div>
      </div>
    </div>
  </div>

    <Box sx={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>Appointment History</Typography>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography variant="h6" sx={{ color: 'red' }}>Error: {error}</Typography>
      ) : (
        <Box>
          {dateExpiredAndRejectedAppointments.length === 0 ? (
            <Typography variant="h6">No Appointments</Typography>
          ) : (
           
              <Grid item xs={12} >
                <table
                    style={{
                      width: "fit-content",
                      borderCollapse: "collapse",
                    }}
                  >
                    <thead>
                      <tr
                        style={{ backgroundColor: "#001973", color: "white", width: "100%" }}
                      >
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "120px",
                          }}
                        >
                          Client
                        </th>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "150px",
                          }}
                        >
                          Service
                        </th>

                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "200px",
                          }}
                        >
                          Car Information
                        </th>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "120px",
                          }}
                        >
                          Price
                        </th>

                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "120px",
                          }}
                        >
                          Client Contact
                        </th>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "170px",
                          }}
                        >
                          Date
                        </th>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            minWidth: "80px",
                          }}
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                  
                   {dateExpiredAndRejectedAppointments.map((appointment) => (
                        <tr key={appointment.id}>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {appointment.customer.first_name}{" "}
                            {appointment.customer.last_name}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {appointment.service.service_name}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            Car Make - {appointment.service.car_make || "NA"}{" "}<br /> 
                            Car Model - {appointment.service.car_model ||"NA"}{" "}<br />
                            Car Type - {appointment.service.car_type ||"NA"}{" "}<br />
                            Car Year - {appointment.service.car_year || "NA"}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            ${appointment.service.service_price ?? "NA"}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {appointment.customer.phone ?? "NA"}
                          </td>
                          <td
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {isoDateToDisplay(appointment.service_date)}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              color:
                                appointment.status === "Rejected"
                                  ? "#F4B400"
                                  : "#4CAF50",
                            }}
                          >
                            {appointment.status}
                          </td>
                          <td>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
              </Grid>
          )}
        </Box>
      )}
    </Box>
    </>
  )
}
export default Appointment
