import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Logout } from "../actions/authActions"
import ShopDashboard from './ShopDashboard';
import UserDashBord from './UserDashBord';
const Dashboard = () => {
  const dispatch = useDispatch();
  const { user , token} = useSelector((state) => state.auth);

  const handleLogout = async() => {
    try{
      await dispatch(Logout(token));
      alert('Logout Successfull');
    }
    catch(err){
      alert('Logout Failed');
    }
    
  };
  

  return (<>
    {user.user_type === 'shop'? (<div> 
      <ShopDashboard />
    </div>) : (
      <div>
  <UserDashBord />

      </div>
  )}

  </>
    

  );
};

export default Dashboard;
