import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  TextField, Button, Grid, Typography, CircularProgress, Snackbar,
  Container, Box
} from '@mui/material';
import axios from 'axios';
import NavbarComponent from '../Components/Navbar/NavbarComponent';
import Footer from '../Components/Footer/Footer';
import { BackendUrl } from '../data/api';
const NewPasswordSet = () => {
  const { code } = useParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async () => {
    setError('');
    setSuccess(false);

    if (password !== confirmPassword) {
      setError("Passwords don't match!");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${BackendUrl}/accounts/password/reset/verified/`, {
        password,
        code,
      });
      setLoading(false);
      setSuccess(true);
      setSnackbarMessage('Password successfully updated!');
      setSnackbarOpen(true);
      console.log('Password:', password);
      console.log('Code:', code);
    } catch (error) {
      setLoading(false);
      setError('Failed to set new password.');
      console.error('Password update failed:', error);
    }
  };

  return (
    <Box>
      <NavbarComponent />
      <Container  component="main" maxWidth="xs"  sx={{ my: 15 }} >

        <Typography variant="h4" textAlign="center" mt={5}>
          Set New Password
        </Typography>
       
       <Box Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              label="New Password"
              type="password"
              variant="outlined"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
            />
          </Box>
          <Box Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              label="Confirm New Password"
              type="password"
              variant="outlined"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              fullWidth
            />
          </Box>
          <Box Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Set New Password'}
            </Button>
    </Box>  
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          />
          {error && (
            <Grid item xs={12}>
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            </Grid>
          )}
          {success && (
            <Grid item xs={12}>
              <Typography variant="body2" color="success">
                Password successfully updated!
              </Typography>
            </Grid>
          )}
       
         
      </Container>
      <Footer/>
    </Box>
  );
}
export default NewPasswordSet;
