import React, { useState } from 'react';
import { Container, Typography, CircularProgress, Box, Button } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import NavbarComponent from '../Components/Navbar/NavbarComponent';
import Footer from '../Components/Footer/Footer';
import { BackendUrl } from '../data/api';


function EmailVarification() {
    const { code } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false); 

    const verifyEmail = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${BackendUrl}/accounts/signup/verify/?code=${code}`);
            if (res.status === 200) {
                console.log('Email verified');
                setError('');
                setSuccess(true); // Set success state to true
            }
        } catch (error) {
            setError(error.response?.data?.detail || 'An error occurred');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box>
            <NavbarComponent/>
        <Container maxWidth="md"   sx={{my : 15}}>
            <Box mt={5} textAlign="center">
                <Typography variant="h4">Email Verification</Typography>
                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <Box mt={2}>
                        <Typography variant="body1" color="error">
                            Error: {error}
                        </Typography>
                        <Typography variant="body1">
                            Invalid Verification Code. Please try logging in again and click on the link in the email.
                        </Typography>
                        <Button variant="contained" color="primary" onClick={verifyEmail} disabled={loading}>
                            Retry Verification
                        </Button>
                    </Box>
                ) : success ? ( // Show success message if success state is true
                    <Box mt={2}>
                        <Typography variant="h5" color="success">Email Verified Successfully!</Typography>
                    </Box>
                ) : (
                    <Box mt={2}>
                        <Typography variant="h5">Click the button below to verify your email:</Typography>
                        <Button variant="contained" color="primary" onClick={verifyEmail} disabled={loading}>
                            Verify Email
                        </Button>
                    </Box>
                )}
            </Box>
        </Container>
        <Footer></Footer>
        </Box>
    );
}

export default EmailVarification;
