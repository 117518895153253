import { combineReducers } from 'redux';
import authReducer from './authReducer';
import shopReducer from './shopReducer';
import searchReducer from './searchReducer';
import apointmentReducer from './apointmentReducer';

const rootReducer = combineReducers({
  auth : authReducer, 
  shop : shopReducer,
  search : searchReducer,
  apointment : apointmentReducer
});

export default rootReducer;
