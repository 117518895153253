import { useState } from 'react';
import React from 'react'
import Footer from '../Components/Footer/Footer';

const ContactComponent = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
      const [formStatus, setFormStatus] = useState(null);
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          // Simulate form submission or make an API call
          console.log(formData); // Log the form data
    
          // Clear form after submission
          setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''
          });
          
          // Set success message
          setFormStatus('success');
        } catch (error) {
          console.error('Error submitting form:', error);
          // Set error message
          setFormStatus('error');
        }
      };
    
  return (
    <div>
      {/* ======= Breadcrumbs ======= */}
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center" style={{backgroundImage: 'url("../assets/img/page-header.jpg")'}}>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Contact</h2>
                <p>
                  Get in touch with us to know more about our services and how we can help you. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li><a href="index.html">Home</a></li>
              <li>Contact</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}
      {/* ======= Contact Section ======= */}
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          {/* <div>
            <iframe style={{border: 0, width: '100%', height: 340}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameBorder={0} allowFullScreen />
          </div> */}
          <div className="row gy-4 mt-4">
            <div className="col-lg-4">
              {/* <div className="info-item d-flex">
                <i className="bi bi-geo-alt flex-shrink-0" />
                <div>
                  <h4>Location:</h4>
                  <p>A108 Adam Street, New York, NY 535022</p>
                </div>
              </div> */}
              <div className="info-item d-flex">
                <i className="bi bi-envelope flex-shrink-0" />
                <div>
                  <h4>Email:</h4>
                  <p>info@throttleauto.ca</p>
                </div>
              </div>
              {/* <div className="info-item d-flex">
                <i className="bi bi-phone flex-shrink-0" />
                <div>
                  <h4>Call:</h4>
                  <p>+1 5589 55488 55</p>
                </div>
              </div> */}
            </div>
            <div className="col-lg-8">
              <form onSubmit={handleSubmit} className="php-email-form">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="form-control"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    className="form-control"
                    id="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    rows={5}
                    placeholder="Message"
                    required
                  />
                </div>
                <div className="my-3">
                  {formStatus === 'success' && (
                    <div className="sent-message">Your message has been sent. Thank you!</div>
                  )}
                  {formStatus === 'error' && (
                    <div className="error-message">Error sending message. Please try again later.</div>
                  )}
                </div>
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default ContactComponent
