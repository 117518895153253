import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import ServiceDesing from "../Components/Home/ServiceDesing";

const AboutCompnent = () => {
  return (
    <div>
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: 'url("../assets/img/page-header.jpg")' }}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>About Us</h2>
                <p>
                  Revolutionizing automobile servicing with transparency and
                  trust, redefining the industry standard for a confident and
                  informed automotive experience.
                </p>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>About</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}
      {/* ======= About Us Section ======= */}
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img src="assets/img/about.jpg" className="img-fluid" alt />
              <a
                href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                className="glightbox play-btn"
              />
            </div>
            <div className="col-lg-6 content order-last  order-lg-first">
              <h3>Mission Statement: </h3>
              <p>
                Our mission is to elevate the car servicing experience by
                fostering transparency, trust, and informed decision-making,
                thereby reducing the gap between the mechanic and the customer.
                We are dedicated to providing accessible and reliable
                information, redefining the industry norms, and ensuring that
                every vehicle owner finds the perfect match with a skilled
                mechanic, empowering both parties to collaborate seamlessly and
                ensuring confidence throughout their automotive servicing
                journey
              </p>
              <ul>
                <li data-aos="fade-up" data-aos-delay={100}>
                  <i className="bi bi-broadcast" />
                  <div>
                    <h5> Connecting Drivers with Trusted Mechanic Shops:</h5>
                    <p>
                      We're dedicated to simplifying the process of finding and
                      booking automotive services. Our platform connects drivers
                      with a curated network of trusted mechanic shops, offering
                      convenience and peace of mind to vehicle owners.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bi bi-journal-check" />
                  <div>
                    <h5> Streamlined Booking Experience: </h5>
                    <p>
                      We understand the importance of a reliable vehicle, which
                      is why we've created an intuitive and user-friendly
                      platform for scheduling appointments with mechanic shops.
                      From routine maintenance to urgent repairs, our
                      streamlined booking process ensures that drivers can get
                      back on the road quickly and safely.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={300}>
                  <i className="bi bi-truck" />
                  <div>
                    <h5>Commitment to Excellence:</h5>
                    <p>
                      We're committed to excellence in every aspect of our
                      business. Whether it's providing exceptional customer
                      service, maintaining strict privacy standards, or
                      fostering positive relationships with our partner mechanic
                      shops, we strive to exceed expectations and deliver an
                      unparalleled experience for our users.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* End About Us Section */}
      {/* ======= Stats Counter Section ======= */}
      {/* <section id="stats-counter" className="stats-counter pt-0">
    <div className="container" data-aos="fade-up">
      <div className="row gy-4">
        <div className="col-lg-4 col-md-6">
          <div className="stats-item text-center w-100 h-100">
            <span data-purecounter-start={0} data-purecounter-end={232} data-purecounter-duration={1} className="purecounter" />
            <p>Clients</p>
            <CountUp start={0} end={100} delay={1}>
                     
                    </CountUp>
                    <p>Automotive Services Available</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="stats-item text-center w-100 h-100">
            <span data-purecounter-start={0} data-purecounter-end={521} data-purecounter-duration={1} className="purecounter" />
            <p>Projects</p>
            <CountUp start={0} end={521} delay={1}>
                     
                    </CountUp>
                    <p>Mechanic Shops Onboarded</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="stats-item text-center w-100 h-100">
            <span data-purecounter-start={0} data-purecounter-end={1453} data-purecounter-duration={1} className="purecounter" />
            <p>Hours Of Support</p>
            <CountUp start={0} end={32} delay={1}>
                      
                    </CountUp>
                    <p>Vehicles Booked</p>
          </div>
        </div>
      </div>
    </div>
  </section> */}
      <ServiceDesing />

      <Footer />
    </div>
  );
};

export default AboutCompnent;
