import React, {
  
} from 'react';
import { Route, Routes,  } from 'react-router-dom';

import {
    
    Box,
} from "@mui/material";


import Footer from '../Components/Footer/Footer';


import ShopHome from '../Components/Shops/ShopHome';
import Appointments from '../Components/Shops/Appointment';
import AutoServices from '../Components/Shops/AutoServices';
import Profile from '../Components/Shops/Profile';


import NavbarComponent from '../Components/Navbar/NavbarComponent'




const ShopDashboard = () => {
    return (
        <>
        <NavbarComponent/>
        <Box>
            <Box component="main" sx={{ flexGrow: 1}}>
                <Routes>
                    <Route path="/" element={<ShopHome/>} />
                    <Route path="appointment" element={<Appointments />} />
                    <Route path="services" element={<AutoServices />} />
                    <Route path="profile" element={<Profile />} />
                </Routes>
            </Box>
            <Footer />
        </Box>
        </>
    );
};

export default ShopDashboard;
