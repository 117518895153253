import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import Featured from "../Components/Home/Featured";
import ServicesComponent from "../Components/Home/ServicesComponent";
const ServiceComponent = () => {
  return (
    <div>
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: 'url("../assets/img/page-header.jpg")' }}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Services</h2>
                <p>
                  Discover popular services offered by Throttle verified
                  business owners. Let us help you find your next reliable auto
                  service provider.
                </p>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Services</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}
      <Featured />
      <ServicesComponent />
      <Footer />
    </div>
  );
};

export default ServiceComponent;
