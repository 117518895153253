import axios from 'axios';
import { BackendUrl } from '../data/api';


// api calls

const createNewApointment = (serviceId , token ,  shopId ,isoDate  ) => {
    return axios.post(`${BackendUrl}/booking/appointments/`, { service: serviceId , shop: shopId  , isoDate :isoDate}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    });
    
}


const fetchAppointments = (token) => {
    return axios.get(`${BackendUrl}/booking/appointments/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    });
}

const acceptAppointment = (appointmentId, token ,  date) => {
    const data = {
        status: 'Approved'
    };
    if (date) {
        data.service_date = date;
    }
    console.log(data);
    return axios.put(`${BackendUrl}/booking/appointments/${appointmentId}/`,
        data    
    , {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    });
}

const rejectAppointment = (appointmentId, token) => {
    return axios.put(`${BackendUrl}/booking/appointments/${appointmentId}/`, {
        status :'Rejected'
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    });
}

const updateAppointmentDate = (appointmentId, newDate, token) => {
    console.log(newDate);
    const isoDate = newDate.toISOString();
    console.log(isoDate);
    return axios.put(`${BackendUrl}/booking/appointments/${appointmentId}/update_date/`, { 
        new_date :isoDate }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    });
}






// actions
const createNewApointmentAction = (serviceId , shopId , isoDate , setService)   => async (dispatch , getState) => {
    try {
        dispatch({ type: 'APPOINTMENT_REQUEST' });
        const { user , token } = getState().auth;
        console.log(user);
        console.log(token);
        const response = await createNewApointment(serviceId , token ,  shopId , isoDate );
        dispatch({ type: 'APPOINTMENT_SUCCESS' , payload: response.data });
        localStorage.removeItem('serviceId');
        setService(null);
    } catch (error) {
        console.log(error);
        dispatch({ type: 'APPOINTMENT_FAIL' , payload: error.response.data });

    }
}

const fetchAppointmentsAction = () => async (dispatch , getState) => {
    try {
        dispatch({ type: 'FETCH_APPOINTMENTS_REQUEST' });
        const { user , token } = getState().auth;
        const response = await fetchAppointments(token);
        console.log(response.data);
        dispatch({ type: 'FETCH_APPOINTMENTS_SUCCESS' , payload: response.data });
    } catch (error) {
        dispatch({ type: 'FETCH_APPOINTMENTS_FAIL' , payload: error.message });
    }
}




const acceptAppointmentAction = (appointmentId , date) => async (dispatch, getState) => {
    console.log(date);
    try {
      // Dispatch an action to indicate the start of the request
      dispatch({ type: 'APPOINTMENT_ACCEPT_REQUEST' });
      const { token } = getState().auth;
      if (date) {
        // update the appointment date in state also 
        dispatch({ type: 'APPOINTMENT_UPDATE_SUCCESS', payload: { appointmentId, newDate: date } });
      const isoDate = date.toISOString();
      console.log(isoDate);
      await acceptAppointment(appointmentId, token , isoDate);
    }
    else {
        await acceptAppointment(appointmentId, token);
    }

      // Dispatch an action to update the appointment status in the state
      dispatch({ type: 'APPOINTMENT_ACCEPT_SUCCESS', payload: appointmentId });
    } catch (error) {
      dispatch({ type: 'APPOINTMENT_ACCEPT_FAIL', payload: error.message });
    }
  };
  
  const rejectAppointmentAction = (appointmentId) => async (dispatch, getState) => {
    try {
      dispatch({ type: 'APPOINTMENT_REJECT_REQUEST' });
      const { token } = getState().auth;
      await rejectAppointment(appointmentId, token);
      dispatch({ type: 'APPOINTMENT_REJECT_SUCCESS', payload: appointmentId });
    } catch (error) {
      dispatch({ type: 'APPOINTMENT_REJECT_FAIL', payload: error.message });
    }
  };
  
  const updateAppointmentDateAction = (appointmentId, newDate) => async (dispatch, getState) => {
    try {
      dispatch({ type: 'APPOINTMENT_UPDATE_REQUEST' });
      const { token } = getState().auth;
      await updateAppointmentDate(appointmentId, newDate, token);
      dispatch({ type: 'APPOINTMENT_UPDATE_SUCCESS', payload: { appointmentId, newDate } });
    } catch (error) {
      dispatch({ type: 'APPOINTMENT_UPDATE_FAIL', payload: error.message });
    }
  };
  
  export { acceptAppointmentAction, rejectAppointmentAction, updateAppointmentDateAction };
  



export { fetchAppointmentsAction };
export { createNewApointmentAction };
