// reducers.js

import {
    FETCH_SERVICES_REQUEST,
    FETCH_SERVICES_SUCCESS,
    FETCH_SERVICES_FAILURE,
    CREATE_SERVICE_REQUEST,
    CREATE_SERVICE_SUCCESS,
    CREATE_SERVICE_FAILURE,
    UPDATE_SERVICE_REQUEST,
    UPDATE_SERVICE_SUCCESS,
    UPDATE_SERVICE_FAILURE,
    DELETE_SERVICE_REQUEST,
    DELETE_SERVICE_SUCCESS,
    DELETE_SERVICE_FAILURE,
  } from '../actions/shopActions'


  
  const initialState = {
    services: [],
    loading: false,
    error: null,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SERVICES_REQUEST:
      case CREATE_SERVICE_REQUEST:
      case UPDATE_SERVICE_REQUEST:
      case DELETE_SERVICE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_SERVICES_SUCCESS:
        return {
          ...state,
          loading: false,
          services: action.payload,
        };
      case CREATE_SERVICE_SUCCESS:
        return {
          ...state,
          loading: false,
          services: [...state.services, action.payload],
        };
      case UPDATE_SERVICE_SUCCESS:
        return {
          ...state,
          loading: false,
          services: state.services.map((service) =>
            service.id === action.payload.id ? action.payload : service
          ),
        };
      case DELETE_SERVICE_SUCCESS:
        return {
          ...state,
          loading: false,
          services: state.services.filter((service) => service.id !== action.payload),
        };
      case FETCH_SERVICES_FAILURE:
      case CREATE_SERVICE_FAILURE:
      case UPDATE_SERVICE_FAILURE:
      case DELETE_SERVICE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case 'SET_SERVICES':
        return {
          ...state,
          services: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  