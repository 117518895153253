import React from "react";
import { Link } from "react-router-dom";
import NavbarComponent from "../Components/Navbar/NavbarComponent";

import ServicesComponent from "../Components/ServiceComponent";
const Services = () => {
  return (
    <main id="main">
      <NavbarComponent />
      <ServicesComponent />
      {/* ======= Breadcrumbs ======= */}
    </main>
  );
};
export default Services;
