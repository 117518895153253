import React, { useState, useEffect } from "react";
import { TextField, List, ListItem, ListItemText, Grid } from "@mui/material";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import Box from '@mui/material/Box';
const PlacesAutocomplete = ({handleAddressChange}) => {
  const [address, setAddress] = useState({
    street: "",
    city: "",
    zip: "",
    province: ""
  });

  const myCallback = (predictions) => {
    console.log("Predictions:", predictions);
    // You can perform any action based on the predictions here
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */

      componentRestrictions: { country: 'CA' }
    },
    debounce: 300,
    callback: myCallback
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = (description) => () => {
    setValue(description.description, false);
    console.log(description);
    clearSuggestions();

    getGeocode({ address: description.description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      const addressComponents = results[0].address_components;


      let newAddress = {
        street: description.structured_formatting.main_text,
        city: "",
        zip: "",
        province: ""
      };

      addressComponents.forEach(component => {
        if (component.types.includes("locality")) {
          newAddress.city = component.long_name;
        } else if (component.types.includes("postal_code")) {
          newAddress.zip = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          newAddress.province = component.long_name;
        }
      });

      setAddress(newAddress);
    
      handleAddressChange(newAddress);
      console.log(newAddress);
      console.log("📍 Coordinates: ", { lat, lng });
    });
  };


  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ListItem key={place_id} onClick={handleSelect(suggestion)} button>
          <ListItemText primary={<strong>{main_text}</strong>} secondary={<small>{secondary_text}</small>} />
        </ListItem>
      );
    });

  return (
    <div ref={ref}>
       <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                ml={2}
            >
               <Grid container spacing={2}>
               <Grid item xs={12} sm={12}>
      <TextField
        value={value}
        onChange={handleInput}
        disabled={!ready}
        label="Address"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      {status === "OK" && (
        <List>
          {renderSuggestions()}
        </List>
      )}
      </Grid> 
        <Grid item xs={12} sm={6}>
          <TextField
            value={address.street}
            onChange={(e) => {
              setAddress({ ...address, street: e.target.value })
              handleAddressChange(address)
            }
            }
            label="Street"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={address.city}
            onChange={(e) => 
              {setAddress({ ...address, city: e.target.value })
              handleAddressChange(address)
            }
            }
            label="City"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={address.zip}
            onChange={(e) => {setAddress({ ...address, zip: e.target.value })
            handleAddressChange(address)
          }

            }
            label="Postal Code"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={address.province}
            onChange={(e) => {setAddress({ ...address, province: e.target.value })
            handleAddressChange(address)
          }
            }
            label="Province"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>
      </Box>
    </div>
  );
};

export default PlacesAutocomplete;
