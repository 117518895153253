import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BackendUrl } from "../../data/api";
import {
  Box,
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  IconButton,
  Grid,
  Rating,
  TextField,
} from "@mui/material";
import dayjs from 'dayjs'


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  createNewApointmentAction,
  fetchAppointmentsAction,
} from "../../actions/apointmentAction";
import { useDispatch } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

import { isoDateToDisplay } from "../../utils";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

// Set the timezone globally
dayjs.tz.setDefault('America/New_York');

export const UserHome = () => {
  const { user, token } = useSelector((state) => state.auth);

  console.log(user);

  const { appointments, loading, error, buttonLoading } = useSelector(
    (state) => state.apointment
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const serviceId = localStorage.getItem("serviceId");
  const [service, setService] = useState(null);
  const [AppointmentDate, setAppointmentDate] = useState(null);

  useEffect(() => {
    dispatch(fetchAppointmentsAction());
  }, [dispatch]);

  const [hasAppointments, setHasAppointments] = useState(false);

  useEffect(() => {
    const upcomingAppointments = appointments.filter(
      (appointment) => appointment.date > new Date()
    );
    setHasAppointments(upcomingAppointments.length > 0);
  }, [appointments]);

  const fetchService = async (id, token) => {
    try {
      const response = await axios.get(`${BackendUrl}/shop/services/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      const data = response.data;
      console.log(data);
      setService(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (serviceId) {
      fetchService(serviceId, token);
    }
  }, [serviceId, token]);

  const handleBooking = (serviceId, shopId) => {
    if (AppointmentDate === null) {
      alert("Please select a date and time for the appointment");
      return;
    }
    if (AppointmentDate < new Date()) {
      alert("Please select a date and time in the future");
      return;
    }
    if (AppointmentDate) {
      const isoDate = AppointmentDate.toISOString();
      console.log(isoDate);
      dispatch(
        createNewApointmentAction(serviceId, shopId, isoDate, setService)
      );
    }
  };
  const handleCancel = () => {
    localStorage.removeItem("serviceId");
    setService(null);
  };

  // Separate appointments based on status
  const pendingAppointments = appointments.filter(
    (appointment) =>
      appointment.status === "Pending" ||
      (appointment.status === "Approved" &&
        new Date(appointment.service_date) >= new Date())
  );
  // const acceptedAppointments = appointments.filter(
  //   (appointment) =>
  //     appointment.status === "Approved" &&
  //     new Date(appointment.service_date) >= new Date()
  // );
  const rejectedAppointments = appointments.filter(
    (appointment) =>
      (appointment.status === "Rejected" &&
        new Date(appointment.service_date) >= new Date()) ||
      new Date(appointment.service_date) < new Date()
  );

  // const completedAppointments = appointments.filter((appointment) => {
  //   // Convert ISO date to JavaScript Date object
  //   const serviceDate = new Date(appointment.service_date);
  //   // Filter out appointments where the service date has passed
  //   return serviceDate < new Date();
  // });

  // console.log(acceptedAppointments);
  console.log(rejectedAppointments);
  // console.log(completedAppointments);
  console.log(pendingAppointments);
  const [reviews, setReviews] = useState({});


  const [submittingReview, setSubmittingReview] = useState(false);
  // Function to handle submission of review for an appointment
  const handleReviewSubmit = async (appointmentId) => {
    console.log("Review submit", appointmentId);
    const reviewText = reviews[appointmentId];
    const rating = reviews[`${appointmentId}-rating`];

    // Validate review text and rating
    if (!reviewText || rating === undefined || rating === null) {
      alert("Please provide both review text and rating.");
      return;
    }

    setSubmittingReview(true);

    console.log(
      `Review for appointment ${appointmentId}: ${reviewText}, Rating: ${rating}`
    );
    await axios.post(
      `${BackendUrl}/booking/reviews/`,
      {
        apointment: appointmentId,
        review: reviewText,
        rating: rating,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    // Clear review text and rating from state
    setReviews((prevState) => {
      const newState = { ...prevState };
      delete newState[appointmentId];
      delete newState[`${appointmentId}-rating`];
      return newState;
    });
    dispatch(fetchAppointmentsAction());
    // Refresh appointments
    setSubmittingReview(false);
  };

  // Function to update review text in state
  const handleReviewTextChange = (appointmentId, text) => {
    setReviews((prevState) => ({
      ...prevState,
      [appointmentId]: text,
    }));
  };

  // Function to update rating in state
  const handleRatingChange = (appointmentId, value) => {
    setReviews((prevState) => ({
      ...prevState,
      [`${appointmentId}-rating`]: value,
    }));
  };

  return (
    <>
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: 'url("assets/img/page-header.jpg")' }}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Welcome, {user.first_name}</h2>
                <p>
                  {/* Here are your appointments ,You can accept or reject them or
                  change time{" "} <br/> Review,  */}
                  Here you can view your appointments and create new appointments
                </p>
              </div>
            </div>
          </div>
        </div>

        <Container>
          <Box
            sx={{
              bgcolor: "background.paper",
              pt: 8,
              pb: 6,
              textAlign: "center",
            }}
          >
            {error && (
              <Typography variant="h6" color="error" gutterBottom>
                {error.message}
              </Typography>
            )}
          </Box>
          {service && (
            <>
              <Grid item xs={12}>
                <Grid style={{ overflowX: "auto", width: "100%" }}>
                  <table
                    style={{
                      width: "fit-content",
                      borderCollapse: "collapse",
                    }}
                  >
                    <Grid item xs={12}>
                      <div style={{ overflowX: "auto", width: "100%" }}>
                        <table
                          style={{
                            width: "fit-content",
                            borderCollapse: "collapse",
                          }}
                        >
                          <thead>
                            <tr
                              style={{ backgroundColor: "#001973", color: "white" }}
                            >
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  minWidth: "120px",
                                }}
                              >
                                Service
                              </th>
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  minWidth: "120px",
                                }}
                              >
                                Shop Name
                              </th>
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  minWidth: "200px",
                                }}
                              >
                                Address
                              </th>
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  minWidth: "120px",
                                }}
                              >
                                Price
                              </th>

                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  minWidth: "120px",
                                }}
                              >
                                Phone
                              </th>
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  minWidth: "120px",
                                }}
                              >
                                Date
                              </th>
                              <th
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  minWidth: "80px",
                                }}
                              >
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr key={service.service.id}>
                              <td
                                style={{ border: "1px solid #ddd", padding: "8px" }}
                              >
                                {service.service.service_name}
                              </td>
                              <td
                                style={{ border: "1px solid #ddd", padding: "8px" }}
                              >
                                {service.shop.shop_name}
                              </td>
                              <td
                                style={{ border: "1px solid #ddd", padding: "8px" }}
                              >
                                {service.shop.street_address},{" "}
                                {service.shop.city}, {service.shop.province},{" "}
                                {service.shop.zip_code}
                              </td>
                              <td
                                style={{ border: "1px solid #ddd", padding: "8px" }}
                              >
                                ${service.service.service_price}
                              </td>

                              <td
                                style={{ border: "1px solid #ddd", padding: "8px" }}
                              >
                                {service.shop.phone}
                              </td>

                            </tr>



                          </tbody>
                        </table>
                      </div>

                    </Grid>
                  </table>
                </Grid>
              </Grid>



              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                  <DateTimePicker
                    label="Appointment Date and Time"
                    value={AppointmentDate}
                    onChange={(newValue) => setAppointmentDate(newValue)}
                    minDateTime={dayjs()} // Restrict past dates
                    renderInput={(params) => (
                      <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                        <IconButton>
                          <FontAwesomeIcon icon={faCalendarPlus} />
                        </IconButton>
                        <input {...params.inputProps} />
                      </Box>
                    )}
                  />
                </Box>
              </LocalizationProvider>
              <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleBooking(service.service.id, service.shop.id)
                  }
                  disabled={buttonLoading}
                  startIcon={<FontAwesomeIcon icon={faCalendarPlus} />}
                >
                  {buttonLoading ? "Booking..." : "Book"}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel}
                  startIcon={<FontAwesomeIcon icon={faTimesCircle} />}
                  sx={{ ml: 2 }}
                >
                  Cancel
                </Button>
              </Box>
            </>
          )}
          {!service && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/search")}
              >
                Explore More Services
              </Button>
            </Box>
          )}

          {/* Pendding  */}
          <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
            <Grid container spacing={1}>
              {pendingAppointments.length === 0 ? null : (
                <Typography variant="h6" color="text.primary" gutterBottom>
                  Upcoming Appointments
                </Typography>
              )}
              {pendingAppointments.length === 0 ? null : (
                <Grid item xs={12}>
                  <div style={{ overflowX: "auto", width: "100%" }}>
                    <table
                      style={{
                        width: "fit-content",
                        borderCollapse: "collapse",
                      }}
                    >
                      <thead>
                        <tr
                          style={{ backgroundColor: "#001973", color: "white" }}
                        >
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "120px",
                            }}
                          >
                            Service
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "120px",
                            }}
                          >
                            Shop Name
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "200px",
                            }}
                          >
                            Address
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "120px",
                            }}
                          >
                            Price
                          </th>

                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "120px",
                            }}
                          >
                            Phone
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "120px",
                            }}
                          >
                            Date
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "80px",
                            }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {pendingAppointments.map((appointment) => (
                          <tr key={appointment.id}>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {appointment.service.service_name}
                            </td>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {appointment.shop.shop_name}
                            </td>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {appointment.shop.street_address},{" "}
                              {appointment.shop.city}, {appointment.shop.province}
                              , {appointment.shop.zip_code}
                            </td>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              ${appointment.service.service_price}
                            </td>

                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {appointment.shop.phone}
                            </td>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {isoDateToDisplay(appointment.service_date)}
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                color:
                                  appointment.status === "Pending"
                                    ? "#F4B400"
                                    : "#4CAF50",
                              }}
                            >
                              {appointment.status}
                            </td>
                            <div className="d-flex">
                              <Button
                                style={{
                                  padding: "10px",
                                  margin: "10px",
                                  border: "2px solid #747264",
                                  width: '200%',
                                  background:
                                    appointment.status === "Pending"
                                      ? "#F6ECA9"
                                      : "#F3F8FF",
                                  color:
                                    appointment.status === "Pending"
                                      ? "#F4B400"
                                      : "#4CAF50",
                                }}
                              >
                                {" "}
                                {appointment.status}{" "}
                              </Button>
                              {/* <Button
                                    style={{
                                      padding: "10px",
                                      margin: "10px 0",
                                      background: "#FFF0CE",
                                      width: '200%',
                                      color: "red",

                                      border: "2px solid #747264",
                                    }}
                                  >
                                    {" "}
                                    Cancel{" "}
                                  </Button> */}
                            </div>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Grid>
              )}
            </Grid>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
            {rejectedAppointments.length > 0 && (
              <Grid container spacing={1}>
                <Typography variant="h6" color="text.primary" gutterBottom>
                  Past Appointments
                </Typography>
                <Grid item xs={12}>
                  <div style={{ overflowX: "auto", width: "100%" }}>
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        width: "fit-content",
                      }}
                    >
                      <thead>
                        <tr
                          style={{ backgroundColor: "#001973", color: "white" }}
                        >
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "120px",
                            }}
                          >
                            Service
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "120px",
                            }}
                          >
                            Shop Name
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "150px",
                            }}
                          >
                            Address
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "120px",
                            }}
                          >
                            Price
                          </th>

                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "120px",
                            }}
                          >
                            Phone
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "120px",
                            }}
                          >
                            Date
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              minWidth: "80px",
                            }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rejectedAppointments.map((appointment) => (
                          <tr key={appointment.id}>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {appointment.service.service_name}
                            </td>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {appointment.shop.shop_name}
                            </td>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {appointment.shop.street_address},{" "}
                              {appointment.shop.city}, {appointment.shop.province}
                              , {appointment.shop.zip_code}
                            </td>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              ${appointment.service.service_price}
                            </td>

                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {appointment.shop.phone}
                            </td>
                            <td
                              style={{ border: "1px solid #ddd", padding: "8px" }}
                            >
                              {isoDateToDisplay(appointment.service_date)}
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                color:
                                  appointment.status === "Rejected"
                                    ? "red"
                                    : "#4CAF50",
                              }}
                            >
                              {appointment.status === "Rejected"
                                ? "Rejected"
                                : "Completed"}
                            </td>
{
  appointment.review ? (
    <td
      style={{
        border: "1px solid #ddd",
        padding: "8px",
        color: appointment.status === "Rejected" ? "green" : "#4CAF50",
      }}
    >
      {/* {appointment.review.rating} stars */}

            {/* showstarts 
             */}
      <Rating
        name="read-only"
        value={appointment.review.rating}
        readOnly
      />
      <br />
       {appointment.review.review}
    </td>
  ) : (
    <>
      <td
        style={{
          border: "1px solid #ddd",
          padding: "8px",
          minWidth: "200px",
        }}
      >
        <TextField
          label="Review"
          variant="outlined"
          value={reviews[appointment.id] || ""}
          onChange={(e) => handleReviewTextChange(appointment.id, e.target.value)}
        />
      </td>
      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
        <Rating
          name={`${appointment.id}-rating`}
          value={reviews[`${appointment.id}-rating`] || 0}
          onChange={(event, newValue) =>
            handleRatingChange(appointment.id, newValue)
          }
        />
      </td>
      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
      <Button
    style={{
        margin: "10px",
        width: "150px",
        border: "2px solid #747264",
    }}
    onClick={() => handleReviewSubmit(appointment.id)}
>
    {submittingReview ? "Submitting..." : "Submit Review"}
</Button>
      </td>
    </>
  )
}
                            
                         


                                    <div >

                                    </div>
                                  </tr>
                          
                        ))}
                                </tbody>
                    </table>
                  </div>
                    </Grid>
                </Grid>
            )}
              </Box>
        </Container>
      </div>
    </>
  );
};
