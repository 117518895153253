import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
function CarModelForm({ onChange }) {
  const [category, setCategory] = useState('');
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [allData, setAllData] = useState([]);
  const [year, setYear] = useState('');

  useEffect(() => {
    setCategory('');
    setMake('');
    setModel('');
    setCategoryOptions([]);
    setMakeOptions([]);
    setModelOptions([]);
  }, [year]); // Reset the selected options when year changes

  const handleYearChange = async (event) => {
    setYear(event.target.value);
    if (event.target.value.length === 4 && !isNaN(event.target.value)) {onChange({
      year: event.target.value,
      make: make,
      category: category,
      model: model
    });
      const where = encodeURIComponent(JSON.stringify({
        "Year": parseInt(event.target.value)
      }));
      const response = await fetch(
        `https://parseapi.back4app.com/classes/Carmodels_Car_Model_List?count=1&where=${where}`,
        {
          headers: {
            'X-Parse-Application-Id': '7j9l7QNKl2GJG1Id02Jb0udd12troAFBoLwQmTbN', // This is your app's application id
            'X-Parse-REST-API-Key': 'ibUYfQuudctxsPF5gwGROa8rtudXMSDpAWf4VivC', // This is your app's REST API key
          }
        }
      );
      const data = await response.json();
      setAllData(data.results);
      if (data.results.length > 0) {
        setMakeOptions([...new Set(data.results.map((item) => item.Make))]);
      }
    }
  };

  const handleMakeChange = (event, value) => {
    setMake(value);
    setCategory('');
    setModel('');onChange({
      year: year,
      make: value,
      category: '',
      model: ''
    });
    const filteredCategoryOptionsBasedOnMake = allData.filter((item) => item.Make === value);
    setCategoryOptions([...new Set(filteredCategoryOptionsBasedOnMake.map((item) => item.Category))]);
  };

  const handleCategoryChange = (event, value) => {
    setCategory(value);
    setModel('');onChange({
      year: year,
      make: make,
      category: value,
      model: ''
    });
    const filteredModelOptionsBasedOnCategory = allData.filter((item) => item.Category === value);
    setModelOptions([...new Set(filteredModelOptionsBasedOnCategory.map((item) => item.Model))]);
  };

  const handleModelChange = (event, value) => {
    setModel(value); onChange({
      year: year,
      make: make,
      category: category,
      model: value
    });
  };

  return (
    <Container> 
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <TextField
          label="Year"
          value={year}
          onChange={handleYearChange}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Autocomplete
          value={make}
          onChange={handleMakeChange}
          options={makeOptions}
          renderInput={(params) => <TextField {...params} label="Make" />}
          disabled={makeOptions.length === 0}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Autocomplete
          value={category}
          onChange={handleCategoryChange}
          options={categoryOptions}
          renderInput={(params) => <TextField {...params} label="Category" />}
          disabled={categoryOptions.length === 0}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Autocomplete
          value={model}
          onChange={handleModelChange}
          options={modelOptions}
          renderInput={(params) => <TextField {...params} label="Model" />}
          disabled={modelOptions.length === 0}
        />
      </Grid>
    </Grid>
    </Container>
  );
}

export default CarModelForm;
