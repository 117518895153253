import React, { useState } from 'react';
import NavbarComponent from '../Components/Navbar/NavbarComponent'
import { Link  , useNavigate , useLocation} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { BackendUrl } from '../data/api';
import { Navigate } from 'react-router-dom';
import { useDispatch , useSelector } from 'react-redux';
import { Dialog, DialogContent, Typography } from '@mui/material';
import CheckoutForm from '../Components/payment/CheckoutForm';
import {Elements} from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51P7HccBL2uRZElUz12YLdNsfqdjziUmIN3vnYmSCuUBGFQzZ3X1IgF5ufbZ5xkj1odilwdcmcIpzXfdqgpzJuIRY00qdy9OyrE');
const PaymentDialog = ({ open, onClose ,  data }) => {
  return (
    <Elements stripe={stripePromise}>
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="h6">Enter Payment Details</Typography>
        <CheckoutForm data={data} />
      </DialogContent>
    </Dialog>
    </Elements>
  );
};

function Pricing() {
  const dispatch =  useDispatch()
  const navigate =  useNavigate()
  const { user  ,  token} = useSelector((state) => state.auth);
  const handlefree = async () => {
    if (!user) {
      navigate('/registershop');
      return;
    }
    else{
      navigate('/dashboard');
    }
  }
  const handlePayment = async (plan) => {
    if (!plan) {
      console.error('Invalid plan selected');
      return;
    }
    if (!user) {
      navigate('/registershop');
      return;
    }
    else{
      if (plan === 'monthly') {
        setSubscriptionData({
          ...subscriptiondata,
          plan: 'monthly',
          price: 19.99,
          currency: 'cad',
          quantity: 1,
          email: user.email,
          name: user.shop_name,
          phone: user.phone,
        });
      }
      else if (plan === 'Quarterly') {
        setSubscriptionData({
          ...subscriptiondata,
          plan: 'Quarterly',
          price: 54.99,
          currency: 'cad',
          quantity: 1,
          email: user.email,
          name: user.shop_name,
          phone: user.phone,
        });
      }
      else if (plan === 'Annually') {
        setSubscriptionData({
          ...subscriptiondata,
          plan: 'Annually',
          price: 199.99,
          currency: 'cad',
          quantity: 1,
          email: user.email,
          name: user.shop_name,
          phone: user.phone,
        });
      }
      handlePaymentOpen();
    }
  };
  const [paymentOpen, setPaymentOpen] = useState(false);
  const handlePaymentOpen = () => {
    setPaymentOpen(true);
  };
  const handlePaymentClose = () => {
    setPaymentOpen(false);
  };

  const [subscriptiondata ,  setSubscriptionData] = useState({
    plan: 'monthly',
    price: 19.99,
    currency: 'cad',
    quantity: 1,
    email: user ? user.email : '',
    name: user ? user.name : '',
    phone: user ? user.phone : '',
  }) 



  return (
    <div>
      <NavbarComponent />
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/page-header.jpg")' }}>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Pricing</h2>
                <p>
                </p>
                <p>
                  *Canadian Dollar

                </p>
                <p>
                  * HST (13% Tax)
                </p>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li><a href="index.html">Home</a></li>
              <li>Pricing</li>
            </ol>
          </div>
        </nav>
      </div>
      <section id="pricing" className="pricing">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay={100}>
              <div className="pricing-item">
                <h3>Monthly:</h3>
                <h4><sup>$</sup>19.99<span> +HST
                </span></h4>
                <ul>
                  <li><i className="bi bi-check" /> Quam adipiscing vitae proin</li>
                  <li><i className="bi bi-check" /> Nec feugiat nisl pretium</li>
                  <li><i className="bi bi-check" /> Nulla at volutpat diam uteera</li>
                  <li className="na"><i className="bi bi-x" /> <span>Pharetra massa massa ultricies</span></li>
                  <li className="na"><i className="bi bi-x" /> <span>Massa ultricies mi quis hendrerit</span></li>
                </ul>
                <button onClick={() => handlePayment('monthly')} className="buy-btn">Buy Now</button>
              </div>
            </div>
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay={200}>
              <div className="pricing-item featured">
                <h3>Quarterly (every 3 months):</h3>
                <h4><sup>$</sup>54.99<span>+HST</span></h4>
                <ul>
                  <li><i className="bi bi-check" /> Quam adipiscing vitae proin</li>
                  <li><i className="bi bi-check" /> Nec feugiat nisl pretium</li>
                  <li><i className="bi bi-check" /> Nulla at volutpat diam uteera</li>
                  <li><i className="bi bi-check" /> Pharetra massa massa ultricies</li>
                  <li><i className="bi bi-check" /> Massa ultricies mi quis hendrerit</li>
                </ul>
                <button onClick={() => handlePayment('Quarterly')} className="buy-btn">Buy Now</button>
              </div>
            </div>{/* End Pricing Item */}
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay={300}>
              <div className="pricing-item">
                <h3>Annually:</h3>
                <h4><sup>$</sup>199.99 <span>+HST</span></h4>
                <ul>
                  <li><i className="bi bi-check" /> Quam adipiscing vitae proin</li>
                  <li><i className="bi bi-check" /> Nec feugiat nisl pretium</li>
                  <li><i className="bi bi-check" /> Nulla at volutpat diam uteera</li>
                  <li><i className="bi bi-check" /> Pharetra massa massa ultricies</li>
                  <li><i className="bi bi-check" /> Massa ultricies mi quis hendrerit</li>
                </ul>
                <button onClick={() => handlePayment('Annually')} className="buy-btn">Buy Now</button>
              </div>
            </div>
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay={200}>
              <div className="pricing-item featured">
                <h3>1 month Free :</h3>
                <h4><sup>$</sup>0<span></span></h4>
                <ul>
                  <li><i className="bi bi-check" />New Registration Exclusive Offer</li>
                  <li><i className="bi bi-check" /> Nec feugiat nisl pretium</li>
                  <li><i className="bi bi-check" /> Nulla at volutpat diam uteera</li>
                  <li><i className="bi bi-check" /> Pharetra massa massa ultricies</li>
                  <li><i className="bi bi-check" /> Massa ultricies mi quis hendrerit</li>
                </ul>
                <button onClick={()=>handlefree()} className="buy-btn">Buy Now</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PaymentDialog open={paymentOpen} onClose={handlePaymentClose} data={subscriptiondata} />
    </div>

  )
}
export default Pricing