// ImageUpload component
import { Grid, Button, ImageList, ImageListItem, IconButton } from "@mui/material";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';

const ImageUpload = ({ profilePictures, handleImageChange, removeProfilePicture, avatar }) => {
  return (
    <Grid item xs={12}>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: 'none' }}
        id="upload-input"
        multiple
      />
      <label htmlFor="upload-input">
        <Button
          variant="contained"
          component="label"
          startIcon={<PhotoCameraIcon />}
          onClick={() => {
            document.getElementById('upload-input').click();
          }}
        >
          Upload Images
        </Button>
      </label>
      <ImageList cols={3} gap={8}>
        {profilePictures && profilePictures.map((item, index) => (
          <ImageListItem key={index}>
            {avatar ?
              (<Avatar alt="Remy Sharp" src={URL.createObjectURL(item)}
                sx={{ width: 100, height: 100 }}
              />) :
              <img src={URL.createObjectURL(item)} alt="images" style={{ width: '100%', height: '100%' }} />
            }
            {removeProfilePicture &&
              <IconButton
                onClick={() => removeProfilePicture(index)}
                sx={{ position: 'absolute', top: 4, right: 4, color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
              >
                <DeleteIcon />
              </IconButton>
            }
          </ImageListItem>
        ))}
      </ImageList>
    </Grid>
  );
};


export default ImageUpload;