import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  Rating,
  Grid,
  Card,
  CardContent,
  Button,
  Container,
  Avatar,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NavbarComponent from '../Components/Navbar/NavbarComponent';
import { BackendUrl, ImageUrl } from '../data/api';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CustomCard } from '../Components/Shops/ShopHome';

const ShopShowcasePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [shop, setShop] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(false);

  const handleAddService = (serviceId) => {
    localStorage.setItem('serviceId', serviceId);
    if (user?.user_type === 'customer') {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    const fetchShop = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${BackendUrl}/shop/shopInDetail`, {
          params: { shop_id: id },
        });
        setShop(response.data.shop);
        console.log(response.data.shop);
        setReviews(response.data.reviews);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchShop();
  }, [id]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Box style={{
      backgroundColor: '#f5f5f5',
      minHeight: '100vh',
      padding: '20px',
      marginTop: '70px',
    }}>
  <NavbarComponent />
  {loading ? (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <CircularProgress />
    </Container>
  ) : (
    <>
      {error && (
        <Typography variant="h6">
          There was an error loading the shop! Please refresh the page.
        </Typography>
      )}
      {shop && (
        <Container maxWidth="lg" 
        >
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
              <Slider {...settings}>
                {shop.images.map((image , index) => (
                  <div key={index}>
                  <Card>
                    <CardContent style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img
                        src={`${ImageUrl}${image.image}`}
                        alt={shop.shop_name}
                        style={{
                          height: "50vh", width: "auto",
                          borderRadius: 8,
                          marginBottom: 10,
                        }}
                      />
                    </CardContent>
                  </Card>
                  </div>
                ))}
              </Slider>
            </Grid> */}

<Grid container spacing={3}>
  <Grid item xs={12}>
    {shop.images.length === 1 ? (
      <div>
        <Card>
          <CardContent style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img
              src={`${ImageUrl}${shop.images[0].image}`}
              alt={shop.shop_name}
              style={{
                height: "50vh", width: "auto",
                borderRadius: 8,
                marginBottom: 10,
              }}
            />
          </CardContent>
        </Card>
      </div>
    ) : (
      <Slider {...settings}>
        {shop.images.map((image , index) => (
          <div key={index}>
            <Card>
              <CardContent style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img
                  src={`${ImageUrl}${image.image}`}
                  alt={shop.shop_name}
                  style={{
                    height: "50vh", width: "auto",
                    borderRadius: 8,
                    marginBottom: 10,
                  }}
                />
              </CardContent>
            </Card>
          </div>
        ))}
      </Slider>
    )}
  </Grid>
</Grid>


            <Grid item xs={12} >
              <Card style={{ padding: 10, textAlign: "center" }}>
                <Typography variant="h4" gutterBottom>
                  {shop.shop_name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {shop.biography}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Phone: {shop.phone}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Website: {shop.website}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Address: {`${shop.street_address}, ${shop.city}, ${shop.province}, ${shop.zip_code}`}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {shop.description}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Container style={{ padding: 10, textAlign: "center" }}>
                <Typography variant="h6" gutterBottom>
                  Shop Services
                </Typography>
                <Grid container spacing={3}>
                  {shop.services.map((service) => (
                    <Grid key={service.id} item xs={12} sm={6}>
                      <CustomCard>
                        <CardContent>
                          <Typography variant="subtitle1" gutterBottom>
                            {service.service_name}
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {service.service_description}
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            Price: ${service.service_price}
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAddService(service.id)}
                          >
                            Add Service
                          </Button>
                        </CardContent>
                      </CustomCard>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12}
            >
              <Typography variant="h6" gutterBottom>
                Reviews
              </Typography>
              <Grid container spacing={3}     direction={'column'}>
                {reviews.map((review) => (
                  <Grid key={review.id} item xs={12} sm={6} md={4}>
                    <Card>
                      <CardContent>
                        <Typography variant="subtitle1" gutterBottom>
                          {`${review.customer.first_name} ${review.customer.last_name}`}
                        </Typography>
                         <Avatar
                          src={`${ImageUrl}${review.customer.profile_picture_path}`}
                          alt={review.customer.first_name}
                        />
                        <Typography variant="body2" gutterBottom>
                          Comment: {review.review}
                        </Typography>
                        <Rating value={review.rating} readOnly />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          </Container>
      )}
    </>
  )}
</Box>
  );
};

export default ShopShowcasePage;
