import React from "react";
import { Hero } from "./Hero";
import Featured from "./Featured";

import Faqs from "./Faqs";

import ServicesComponent from "./ServicesComponent";
const Home = () => {
  return (
    <>
    <div>
      <Hero/> 
          <Featured/>
      <ServicesComponent/>
          <Faqs/>
</div>

    </>
  );
}

export default Home;
