const initialState = {
  user: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,
  token: null, 
  user_type: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REGISTER_START':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
      case 'REGISTER_SUCCESS' :
        return {
          ...state,
          isLoading: false,
          error: null,
        };
      case 'FAILURE':
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      case 'LOGIN_START':
        return {
          ...state,
          isLoading: true,
        };
      case 'LOGIN_SUCCESS':
        return {
          ...state,
          token: action.payload.token,
          error: null,
        };
      case 'LOGOUT':
        return {
          ...state,
          token: null,
        };
      case 'USER_LOADED':
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload,
          token:localStorage.getItem('token'),
          isLoading: false,
          error: null,
        };
      case 'CLEAR_ERROR':
        return {
          ...state,
          error: null,
        };
        default:
          return state;
  }
}
export default authReducer;